<template>
<div>
  <div class="background-image">
    <div class="d-flex justify-content-center">
      <div class="card opacity-80 full-height">
        <div class="card-body p-5 d-flex align-items-center">
          <div>
            <h1 class="text-center font-family-billion">
              HELLO
            </h1>
            <form @submit.prevent="sendEmail()" class="font-family-garamond">
              <div class="mt-4">
                <label for="name" class="form-label">Your name<span class="text-danger">*</span></label>
                <input type="text" name="name" class="form-control" placeholder="It's me Mariooo!" id="name" v-model="name">
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <div class="mt-4">
                    <label for="InputEmail" class="form-label">Email address<span class="text-danger">*</span></label>
                    <input type="email" name="email" class="form-control" id="InputEmail" placeholder="you@email.com"
                           aria-describedby="emailHelp" v-model="email">
                  </div>
                </div>
                <div class="col">
                  <div class="mt-4">
                    <label for="phoneNumber" class="form-label">Phone Number<span class="text-danger">*</span></label>
                    <input type="tel" name="phone" class="form-control" placeholder="07xxxxxxxx" id="phoneNumber" v-model="phone">
                  </div>
                </div>
              </div>
              <div class="mt-4">
                <label for="mailBody" class="form-label">What can I do for you?<span class="text-danger">*</span></label>
                <textarea class="form-control" name="message" id="mailBody" rows="12"
                   placeholder="Please fill out the form below with as many details as possible and I’ll be in touch shortly"
                   v-model="message"></textarea>
              </div>
              <p class="mb-0 mt-2">
                <span class="text-danger">*</span> mandatory fields
              </p>
              <div class="d-block mt-2">
                <button type="submit" :title="disableSend ? 'Please complete all fields' : ''"
                        class="btn btn-lg btn-outline-secondary font-family-garamond w-100" :disabled="disableSend || loading">
                      <template v-if="loading"> 
                        <div class="spinner-border gold" role="status" style="margin-bottom: -7px">
                          <span class="visually-hidden">Loading...</span>
                        </div>
                        <span class="mb-4">
                          Sending Email...
                        </span>
                      </template>
                      <template v-else>
                        Send Email
                      </template>
                </button>
              </div>
              <div v-if="afterSubmitOptions.message.length" class="d-flex mt-4">
                <span :class="afterSubmitOptions.status" class=" text-center font-family-garamond w-100">{{afterSubmitOptions.message}}</span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="onDesktop">
    <div class="row g-0 mt-5">
      <div class="col border-options d-flex justify-content-center align-items-center" style="height:300px">
          Thanks so much for your interest in my photography!! <br/>
          You will hear from me as soon as possible
      </div>
      <div class="col border-options d-flex justify-content-center align-items-center">
            I do my best to reply within 48 hours
      </div>
      <div class="col d-flex justify-content-center align-items-center" style="height:300px" >
          Please check your spam folder if you didn't hear from me <br/>within 48 hours!
          As every event is unique, please share with <br/> me as many details as you can regarding your wedding day,
          and <br/> I will send you an estimate.<br/>
      </div>
    </div>
  </div>
  <div class="onMobile">
    <div class="row g-0 text-center mt-5">
      <div class="col-sm-12">
        Thanks so much for your interest in my photography!!
        <p>You will hear from me as soon as possible</p>
        <p>Please check your spam folder if you didn't hear from me <br/>within 48 hours!
          As every event is unique, please share with <br/> me as many details as you can regarding your wedding day,
          and <br/> I will send you an estimate.<br/></p>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import emailjs from 'emailjs-com';

export default {
  data() {
    return {
      name: '',
      email: '',
      phone: '',
      message: '',
      afterSubmitOptions: {
        message: '',
        status: '',
      },
      loading: false,
    }
  },
  computed: {
    disableSend(){
      if (this.name.length && this.email.length && this.phone.length && this.message.length) {
        return false;
      }
      return true;
    }
  },
  mounted() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    localStorage.setItem('route', 'contacts');
    window.dispatchEvent(new CustomEvent('route-localstorage-changed', {
      detail: {
        storage: localStorage.getItem('route')
      }
    }));
  },
  methods: {
    resetData() {
      this.name = '';
      this.message = '';
      this.email = '';
      this.phone = '';
    },
    sendEmail() {
      const mailParams = {
        name: this.name,
        phone: this.phone,
        message: this.message,
        replay_to_email: this.email
      };
      
      this.loading = true;
      emailjs.send('service_67rx4hb', 'template_z3r16jw', mailParams, 'user_sjv622PnS3XKvxK5Sc6OS')
      .then(() => {
        this.afterSubmitOptions.message = 'Email sent successfully!';
        this.afterSubmitOptions.status = 'alert alert-success';
        this.resetData();
        this.loading = false;
      }, () => {
        this.afterSubmitOptions.message = 'Something went wrong! Try again soon!';
        this.afterSubmitOptions.status = 'alert alert-danger';
        this.resetData();
        this.loading = false;
      });
    }
  }
}
</script>

<style scoped>
@media (min-width: 992px) {
    .onMobile {
      display: none;
    }
    .background-image {
      background-image: url("../assets/contacts/contact.jpg");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      height: 100vh;
    }
    .full-height {
      height: 100vh;
    }
}
@media (max-width: 992px) {
    .background-image {
      background-image: url("../assets/contacts/contact.jpg");
      background-size: auto;
      background-repeat: no-repeat;
    }
    .full-height {
      height: inherit;
    }
    .onDesktop {
        display: none;
    }
}
.opacity-80 {
  opacity: 0.8;
}
.font-family-garamond {
  font-family: 'Cormorant Garamond', serif;
  letter-spacing: 2px;
}
.font-family-billion {
  font-family: 'Billion';
  font-size: 35px;
  font-weight: 600;
  color:#D4AF37;
}
.border-options {
  border-right: 2px solid #D4AF37!important;
}
.gold {
  color:#D4AF37;
}
</style>