import Vue from 'vue'
import VueRouter from 'vue-router'
import VueViewer from 'v-viewer'
import App from './App.vue'
import Gallery from './components/Gallery.vue'
import Contacts from './components/Contacts.vue'
import MainContainer from './components/MainContainer.vue'
import AboutMe from './components/AboutMe.vue'
import NotFound from './components/NotFound.vue'
import Blog from './components/Blog.vue'
import GalleryImmages from './components/GallerySections/GalleryImmages.vue'

Vue.config.productionTip = false
Vue.use(VueRouter);
Vue.use(VueViewer)

const routes = [
  { path: '/', component: MainContainer },
  { path: '/gallery', name: 'gallery', component: Gallery, props: true},
  { path: '/contacts', component: Contacts },
  { path: '/about-me', component: AboutMe },
  { path: '/blog', component: Blog },
  { path: '/wedding/:title', component: GalleryImmages },
  { path: '/lifestyle/:title', component: GalleryImmages },
  { path: '/family/:title', component: GalleryImmages },
  { path: '/fashion/:title', component: GalleryImmages },
  { path: '/social-events/:title', component: GalleryImmages },
  { path: '/product-photo/:title', component: GalleryImmages },
  { path: '*', component: NotFound },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
