<template>
  <div>
    <p class="text-center background billion-font text-muted my-4">Here you can find some of my art, I hope you like it as much as I do</p>
    <div class="container">

      <!-- Wedding Modal -->
      <div class="modal fade" id="weddingModal" tabindex="-1" aria-labelledby="weddingModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title billion-font d-flex mx-auto" id="weddingModalLabel">Weddings</h5>
              <button type="button" class="btn-close m-0" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">              
              <div class="row justify-content-start">
                <div v-for="(key, index) in Object.keys(weddingNames)" :key="index" class="col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center mb-3">
                  <div>
                    <a data-bs-toggle="modal" data-bs-target="#weddingModal">
                      <div @click="goToImages('wedding',`${weddingNames[key].brideName}-${weddingNames[key].groomName}`)" class="image-wrapper-modal onHover">
                        <img :src="require(`@/assets/gallery/wedding/${weddingNames[key].folder}/${weddingNames[key].imgSrc}.jpg`)"
                              class="img-wedding-modal"/>
                        <p class="image-description-modal d-flex justify-content-center my-auto">
                          <span class="d-flex align-items-center">
                            {{weddingNames[key].brideName}} &amp; {{weddingNames[key].groomName}} 
                          </span>
                        </p>
                      </div>
                    </a>
                    <div class="text-center px-5 mt-5">
                      <footer class="blockquote-footer">
                        <cite>Click the photo to see more with {{weddingNames[key].brideName}} &amp; {{weddingNames[key].groomName}}</cite>
                      </footer>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- LifeStyle Modal -->
      <div class="modal fade" id="lifestyleModal" tabindex="-1" aria-labelledby="lifestyleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title billion-font d-flex mx-auto" id="lifestyleModalLabel">Lifestyle</h5>
              <button type="button" class="btn-close m-0" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">              
              <div class="row justify-content-start">
                <div v-for="(key, index) in Object.keys(lifeStyleNames)" :key="index" class="col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center mb-3">
                  <div>
                    <a data-bs-toggle="modal" data-bs-target="#lifestyleModal">
                      <div @click="goToImages('lifestyle', lifeStyleNames[key].placeName)" class="image-wrapper-modal onHover">
                        <img :src="require(`@/assets/gallery/lifestyle/${lifeStyleNames[key].folder}/${lifeStyleNames[key].imgSrc}.jpg`)"
                              class="img-lifestyle-modal"/>
                        <p class="image-description-modal d-flex justify-content-center my-auto">
                          <span class="d-flex align-items-center">
                            {{lifeStyleNames[key].placeName}}
                          </span>
                        </p>
                      </div>
                    </a>
                    <div class="text-center px-5 mt-5">
                      <footer class="blockquote-footer">
                        <cite>Click the photo to see more of {{lifeStyleNames[key].placeName}}</cite>
                      </footer>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

        <!-- Family Modal -->
      <div class="modal fade" id="familyModal" tabindex="-1" aria-labelledby="familyModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title billion-font d-flex mx-auto" id="familyModalLabel">Family</h5>
              <button type="button" class="btn-close m-0" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">              
              <div class="row justify-content-start">
                <div v-for="(key, index) in Object.keys(familyNames)" :key="index" class="col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center mb-3">
                  <div>
                    <a data-bs-toggle="modal" data-bs-target="#familyModal">
                      <div @click="goToImages('family', familyNames[key].name)" class="image-wrapper-modal onHover">
                        <img :src="require(`@/assets/gallery/family/${familyNames[key].folder}/${familyNames[key].imgSrc}.jpg`)"
                              class="img-family-modal"/>
                        <p class="image-description-modal d-flex justify-content-center my-auto">
                          <span class="d-flex align-items-center">
                            {{familyNames[key].name}}
                          </span>
                        </p>
                      </div>
                    </a>
                    <div class="text-center px-5 mt-5">
                      <footer class="blockquote-footer">
                        <cite>Click the photo to see more of {{familyNames[key].name}}</cite>
                      </footer>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Fashion Modal -->
      <div class="modal fade" id="fashionModal" tabindex="-1" aria-labelledby="fashionModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title billion-font d-flex mx-auto" id="fashionModalLabel">Fashion</h5>
              <button type="button" class="btn-close m-0" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">              
              <div class="row justify-content-start">
                <div v-for="(key, index) in Object.keys(fashionNames)" :key="index" class="col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center mb-3">
                  <div>
                    <a data-bs-toggle="modal" data-bs-target="#fashionModal">
                      <div @click="goToImages('fashion', fashionNames[key].name)" class="image-wrapper-modal onHover">
                        <img :src="require(`@/assets/gallery/fashion/${fashionNames[key].folder}/${fashionNames[key].imgSrc}.jpg`)"
                              class="img-fashion-modal"/>
                        <p class="image-description-modal d-flex justify-content-center my-auto">
                          <span class="d-flex align-items-center">
                            {{fashionNames[key].name}}
                          </span>
                        </p>
                      </div>
                    </a>
                    <div class="text-center px-5 mt-5">
                      <footer class="blockquote-footer">
                        <cite>Click the photo to see more of {{fashionNames[key].name}}</cite>
                      </footer>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Social Modal -->
      <div class="modal fade" id="socialModal" tabindex="-1" aria-labelledby="socialModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title billion-font d-flex mx-auto" id="socialModalLabel">Social</h5>
              <button type="button" class="btn-close m-0" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">              
              <div class="row justify-content-start">
                <div v-for="(key, index) in Object.keys(socialNames)" :key="index" class="col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center mb-3">
                  <div>
                    <a data-bs-toggle="modal" data-bs-target="#socialModal">
                      <div @click="goToImages('social-events', socialNames[key].name)" class="image-wrapper-modal onHover">
                        <img :src="require(`@/assets/gallery/social/${socialNames[key].folder}/${socialNames[key].imgSrc}.jpg`)"
                              class="img-social-modal"/>
                        <p class="image-description-modal d-flex justify-content-center my-auto">
                          <span class="d-flex align-items-center">
                            {{socialNames[key].name}}
                          </span>
                        </p>
                      </div>
                    </a>
                    <div class="text-center px-5 mt-5">
                      <footer class="blockquote-footer">
                        <cite>Click the photo to see more of {{socialNames[key].name}}</cite>
                      </footer>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div class="row justify-content-center mt-5">
        <div class="col-lg-4 col-md-12 col-sm-12 d-flex justify-content-center mb-3">
          <a data-bs-toggle="modal" data-bs-target="#lifestyleModal">
            <div class="image-wrapper onHover">
              <img src="../assets/gallery/lifestyle/lifestyle-cover.jpg" class="img-gallery"/>
              <p class="image-description d-flex justify-content-center my-auto">
                <span class="d-flex align-items-center cormorant-font">
                  LIFESTYLE
                </span>
              </p>
            </div>
          </a>
        </div>
        <div class="col-lg-4 col-md-12 col-sm-12 d-flex justify-content-center mb-3">
          <a data-bs-toggle="modal" data-bs-target="#weddingModal">
            <div class="image-wrapper onHover">
              <img src="../assets/gallery/wedding/wedding-cover.jpg" alt="wedding-cover-image" class="img-gallery"/>
              <p class="image-description d-flex justify-content-center my-auto">
                <span class="d-flex align-items-center cormorant-font">
                  WEDDINGS
                </span>
              </p>
            </div>
          </a>
        </div>
        <div class="col-lg-4 col-md-12 col-sm-12 d-flex justify-content-center mb-3">
          <a data-bs-toggle="modal" data-bs-target="#socialModal">
            <div class="image-wrapper onHover">
              <img src="../assets/gallery/social/social-cover.jpg" alt="social-cover-image" class="img-gallery"/>
              <p class="image-description d-flex justify-content-center my-auto">
                <span class="d-flex align-items-center cormorant-font">
                  SOCIAL EVENTS
                </span>
              </p>
            </div>
          </a>
        </div>
        <div class="col-lg-4 col-md-12 col-sm-12 d-flex justify-content-center mb-3">
          <a data-bs-toggle="modal" data-bs-target="#fashionModal">
            <div class="image-wrapper onHover">
              <img src="../assets/gallery/fashion/fashion-cover.jpg" alt="fashion-cover-image" class="img-gallery"/>
              <p class="image-description d-flex justify-content-center my-auto">
                <span class="d-flex align-items-center cormorant-font">
                  FASHION
                </span>
              </p>
            </div>
          </a>
        </div>
        <div class="col-lg-4 col-md-12 col-sm-12 d-flex justify-content-center mb-3">
          <a data-bs-toggle="modal" data-bs-target="#familyModal">
            <div class="image-wrapper onHover">
              <img src="../assets/gallery/family/family-cover.jpg" alt="family-cover-image" class="img-gallery"/>
              <p class="image-description d-flex justify-content-center my-auto">
                <span class="d-flex align-items-center cormorant-font">
                  FAMILY
                </span>
              </p>
            </div>
          </a>
        </div>
        <div class="col-lg-4 col-md-12 col-sm-12 d-flex justify-content-center mb-3">
          <div @click="goToImages('product-photo', 'prod')" class="image-wrapper onHover">
            <img src="../assets/gallery/prod/prod-cover.jpg" alt="prodcover" class="img-gallery"/>
            <p class="image-description d-flex justify-content-center my-auto">
              <span class="d-flex align-items-center cormorant-font">
                PRODUCT PHOTOGRAPHY
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'viewerjs/dist/viewer.css'

export default {
  name: "Gallery",
  props: {
    aModalToOpen: {
      type: String,
      default: ''
    },
 },
  data() {
    return {
      weddingNames: {
        1: {
          brideName: "Ana",
          groomName: "Ciprian",
          folder: 'ana-ciprian',
          imgSrc: "ana-ciprian-cover"
        },
        2: {
          brideName: "Andreea",
          groomName: "Silviu",
          folder: 'andreea-silviu',
          imgSrc: "andreea-silviu-cover"
        },
        3: {
          brideName: "Ioana",
          groomName: "Augustin",
          folder: 'ioana-augustin',
          imgSrc: "ioana-augustin-cover"
        },
        4: {
          brideName: "Ramona",
          groomName: "Fabian",
          folder: 'ramona-fabian',
          imgSrc: "ramona-fabian-cover"
        },
      },
      lifeStyleNames: {
        1: {
          placeName: 'Bucharest by night',
          folder: 'bucharest',
          imgSrc: 'bucharest-cover',
        },
        2: {
          placeName: 'Cinque Terre',
          folder: 'cinque-terre',
          imgSrc: 'cinque-terre-cover',
        },
        3: {
          placeName: 'Rome',
          folder: 'rome',
          imgSrc: 'rome-cover',
        },
      },
      familyNames: {
        1: {
          name: 'Adina, Florin & Tavi',
          folder: 'adina-tavi',
          imgSrc: 'adina-tavi-cover',
        },
        2: {
          name: 'Andreea\'s pregnancy',
          folder: 'andreaa-pregnancy',
          imgSrc: 'andreaa-pregnancy-cover',
        },
        3: {
          name: 'Olivia Maria\'s baptism',
          folder: 'maria-baptism',
          imgSrc: 'maria-baptism-cover',
        },
      },
      fashionNames: {
        1: {
          name: 'Eliza',
          folder: 'eliza',
          imgSrc: 'eliza-cover',
        },
        2: {
          name: 'Luiza Maria',
          folder: 'luiza',
          imgSrc: 'luiza-cover',
        },
        3: {
          name: 'Marinela',
          folder: 'marinela',
          imgSrc: 'marinela-cover',
        },
      },
      socialNames: {
        1: {
          name: 'TEDx Bucharest',
          folder: 'tedx-bucharest',
          imgSrc: 'tedx-bucharest-cover',
        },
        2: {
          name: 'TEDx Universitatea Politehnica Bucharest',
          folder: 'tedx-poli',
          imgSrc: 'tedx-poli-cover',
        },
      },
    }
  },
  mounted() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    localStorage.setItem('route', 'gallery');
    window.dispatchEvent(new CustomEvent('route-localstorage-changed', {
      detail: {
        storage: localStorage.getItem('route')
      }
    }));
    if (this.aModalToOpen.length) {
      const myModal = new bootstrap.Modal(document.getElementById(`${this.aModalToOpen}Modal`), {}) // eslint-disable-line
      myModal.show();
    }
  },
  methods: {
    goToImages(linkPath, linkTitle) {
      let titleParsed = linkTitle.replaceAll('& ','').replaceAll('\'','').replaceAll(' ', '-').replaceAll(',', '').toLowerCase()
      this.$router.push({ path: `/${linkPath}/${titleParsed}` });
    }
  },
  beforeDestroy() {
    const modal = document.querySelector('.modal-backdrop');
    if (modal) {
      modal.remove();
    }
  }
}
</script>

<style scoped>
.cormorant-font {
  letter-spacing: 4px;
  font-family: 'Cormorant Garamond', serif;
  color: rgb(0 0 0 /55%);
}
.billion-font {
  font-family: 'Billion';
  font-size: 4vmax;
}
.onHover {
  cursor: pointer;
  transition: all 1s;
  transform-origin: 50% 50%;
}
.onHover:hover {
  transform: scale(1.05);
}
.image-wrapper {
  position: relative;
}
.image-description {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: #000;
  font-size: 25px;
}
.image-wrapper:hover .image-description {
  color: #fff;
  background: rgba(59, 59, 57, 0.76);
}
.image-wrapper:hover .cormorant-font {
  color: #fff;
}

.image-description-modal {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: #fff;
  opacity: 0;
  visibility: hidden;
}
.image-wrapper-modal:hover .image-description-modal {
  background: rgba(59, 59, 57, 0.76);
  visibility: visible;
  opacity: 1;
}

.img-gallery {
  max-width: 100%;
  height: 556px;
  object-fit: cover;
}
.img-wedding-modal {
  max-width: 100%;
  height: 766px;
  object-fit: cover;
}
.img-lifestyle-modal {
  max-width: 100%;
  height: 713px;
  object-fit: cover;
}
.img-family-modal {
  max-width: 100%;
  height: 721px;
  object-fit: cover;
}
.img-fashion-modal {
  max-width: 100%;
  height: 756px;
  object-fit: cover;
}
.img-social-modal {
  max-width: 100%;
  height: 721px;
  object-fit: cover;
}
.background {
  background: #F0EFEB;
}
</style>