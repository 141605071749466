<template>
  <div id="app">
    <NavBar/>
    <router-view/>
    <Footer/>
  </div>
</template>

<script>
import NavBar from './components/NavBar.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    NavBar,
    Footer,
  }
}
</script>

<style>
@font-face {
    font-family: LunaPiena;
    src: url("./assets/fonts/BrillanteLunaPiena-Italic.otf") format("opentype"),
        url("./assets/fonts/BrillanteLunaPiena-Italic.woff") format("woff"),
        url("./assets/fonts/BrillanteLunaPiena-Italic.ttf")  format("truetype");
}
@font-face {
    font-family: Billion;
    src: url("./assets/fonts/Billion.otf") format("opentype");
}
</style>
