<template>
<nav class="navbar sticky-top navbar-expand-lg navbar-light bg-white">
  <div class="container-fluid">
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
        data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-center" id="navbarNavAltMarkup">
      <div class="navbar-nav">
        <router-link @click.native="setRouteAndClose('homepage')" class="homepage-first nav-link font-family" title="Go to homepage" to="/">
          <span class="d-flex justify-content-center">
            OANA ŞUBEA
          </span>
        </router-link>
        <router-link @click.native="setRouteAndClose('about')" class="nav-link underline my-auto font-family" :class="checkIfThisRoute('about')" to="/about-me">
           <span class="d-flex justify-content-center">ABOUT ME</span> 
        </router-link>
        <router-link @click.native="setRouteAndClose('gallery')" class="nav-link underline my-auto font-family" :class="checkIfThisRoute('gallery')" to="/gallery">
           <span class="d-flex justify-content-center">GALLERY</span> 
        </router-link>
        <router-link @click.native="setRouteAndClose('homepage')" class="nav-link font-family mainPage" title="Go to homepage" to="/">
          OANA ŞUBEA
        </router-link>
        <router-link @click.native="setRouteAndClose('contacts')" class="nav-link underline my-auto font-family" :class="checkIfThisRoute('contacts')" to="/contacts">
             <span class="d-flex justify-content-center">CONTACTS</span> 
        </router-link>
        <router-link @click.native="setRouteAndClose('blog')" class="nav-link my-auto underline font-family" :class="checkIfThisRoute('blog')" to="/blog">
            <span class="d-flex justify-content-center">BLOG</span> 
        </router-link>
      </div>
    </div>
  </div>
</nav>

</template>

<script>
export default {
  data() {
    return {
      selectedTab: localStorage.getItem('route') || 'homepage',
    }
  },
  mounted() {
    window.addEventListener('route-localstorage-changed', (event) => {
      this.selectedTab = event.detail.storage;
    });
  },
  methods: {
    setRouteAndClose(aRouteParam) {
      this.selectedTab = aRouteParam;
      const modal = document.getElementById('navbarNavAltMarkup');
      modal.classList.remove('show');
    },
    checkIfThisRoute(aStringRoute) {
      if (this.selectedTab === aStringRoute) {
        return 'background-pink'
      }
      return '';
    }
  },
}
</script>

<style>
@media (min-width: 992px) {
    .homepage-first {
      display: none;
    }
    .mainPage {
      font-size: 30px;
      letter-spacing: 3px;
    }
}
@media (max-width: 992px) {
    .mainPage {
        display: none;
    }
}
.font-family {
  font-family: 'Cormorant Garamond', serif;
  letter-spacing: 4px;
}
.background-pink {
  background: #F0EFEB;
}
.underline {
  text-decoration: none;
  position: relative;
}

.underline:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 100%;
  background: black;
  opacity: 0;
  transition: 1s all ease-in-out;
}

.underline:hover:after {
  opacity: 1;
}
</style>