<template>
  <div>
    <div class="background-color">
      <div class="container">
          <div class="row">
            <div class="col-lg-6 col-md-12 col-sm-12 p-5">
              <p>About <span class="fontFamily">Oana</span></p>
              <p class="justifyText custom-font">
                Hello, I’m Oana. I love art in every way it can be expressed, but photography has
                conquered me for good and it’s what I do best.
                <br>
                <br>
                I come from a small village near Ploiești, Romania. Now I am living in Bucharest.
                Even if I have my licence degree in engineering, I have never abandoned my passion
                for photography. During my time in college I worked as a photographer for “Electronics
                students league” and later on for “<i>TEDX University Polytechnic of Bucharest</i>”.
                <br>
                <br>
                I’m a very empathetic person, I love to make everyone around me smile, this is why my
                photoshoots are full of joy and good energy.
                <br>
                <br>
                My camera is my extension but I strongly believe that is not the equipment that defines
                a good photographer. It’s more about his talent and vision.
                <br>
                <br>
                <router-link to="/contacts" class="decorations">
                  <span class="text-muted">
                     With all that being said, <br>
                     Let’s make art together!
                  </span>
                </router-link>
              </p>
              <hr style="height: 2px">
              <p class="custom-font justifyText">
                Hei, sunt Oana. Iubesc arta în toate formele ei însă fotografia m-a câștigat definitv
                și este lucrul la care mă pricep cel mai bine.
                <br>
                <br>
                Provin dintr-un sat de lângă Ploiesti, in prezent trăind in București.  Deși am terminat
                o facultate tehnică, în domeniul ingineriei, nu am abandonat pasiunea pentru fotografie
                niciodată. Din acest motiv, în timpul facultății am activat ca fotograf pentru “<i>Liga Studenților
                Electroniști</i>” iar mai apoi pentru “<i>TEDX Universitatea Politehnica din București</i>”.
                <br>
                <br>
                Sunt o persoană foarte empatică, imi place să-i fac pe cei din jurul meu să zâmbească,
                de aceea și ședințele foto pe care le organizez sunt pline de veselie.
                <br>
                <br>
                Aparatul foto este extensia mea însă cred foarte tare că nu aparatura este cea care
                definește un fotograf, ci, mai de grabă, viziunea și talentul lui.
                <br>
                <br>
                <router-link to="/contacts" class="decorations">
                  <span class="text-muted">
                    Te invit, așadar, să creăm artă împreună!
                  </span>
                </router-link>
              </p>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center align-items-center">
              <img src="../assets/about/about.gif" class="img-fluid" alt="about" width="700px"/>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    localStorage.setItem('route', 'about');
    window.dispatchEvent(new CustomEvent('route-localstorage-changed', {
      detail: {
        storage: localStorage.getItem('route')
      }
    }));
  }
}
</script>

<style scoped>
.background-color {
  background: #F0EFEB;
}
.fontFamily {
  font-family: 'LunaPiena';
  font-size: 60px;
}
.decorations {
  text-decoration: none;
  color: black;
  cursor: pointer;
}
.justifyText {
  text-align: center;
}
.custom-font {
  font-family: 'Cormorant Garamond', serif;
  color: rgb(0 0 0 / 55%);
  letter-spacing: 1px;
}
</style>