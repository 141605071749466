<template>
  <div>
    <div class="background-color">
      <div class="container">
        <div class="row d-flex justify-content-center">
          <div class="col-lg-4">
            <img :src="computeCoverImage"
									height="400px" class="img-cover" style="object-fit:contain;"/>
          </div>
          <div class="d-flex justify-content-center my-auto col-lg-6">
            <div class="mt-3">
              <h3 class="custom-font text-center">{{ title }}</h3>
				<span v-if="title === 'Products'" class="text-center custom-font">
				For orders and personalized items check
					<a href="https://www.instagram.com/lunatique.jewelry/" target="_blank" style="color:rgb(0 0 0 /55%)">@lunatique.jewelry</a>
				</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container mt-4">
			<div class="d-flex justify-content-center">
				<template v-if="Object.keys(toUseArray).length <= 0">
					<h3>Something went wrong, please contact us as soon as possible</h3>
				</template>
				<ul v-else class="mx-auto me-4">
					<li v-for="(key, index) in Object.keys(toUseArray)" :key="index">
						<a :href="`https://drive.google.com/uc?export=view&id=${toUseArray[key]}`"
							data-fancybox="gallery">
						<img class="pointer"
							data-caption=""
							:src="require(`@/assets/gallery/${category}/${folder}/img-${key}.jpg`)"
							:alt="index"
							loading=lazy/>	
						</a>
					</li>
				</ul>
			</div>
    </div>
	<template v-if="displayGoTop">
		<div class="bottom-right-page">
			<button title="Go to top" @click="scrollToTop()" class="btn btn-lg mb-1 px-4" style="font-size: 30px!important">
				<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up" viewBox="0 0 16 16">
					<path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"/>
				</svg>
			</button>
		</div>
	</template>
  </div>
</template>

<script>
export default {
  data() {
    return {
		title: '',
		category: '',
		folder: '',
		bucharest: {
			0: '1VkdFs-K8edhsXqjM3yyadYbuNCYPKAYN',
			1: '1O9FbNJtJy6f1b8JuobFSk6r5292SG_5J',
			2: '1VU8eTv7Ot1IhqjhPDh_k9BBbhYx2c9f_',
			3: '1ikspVszkNUWPXndwPG9P0P8M6lx14FPr',
			4: '1F9OMrbYVvWr5_yqUUfCbMBAbwlMtQ_Wy',
			5: '1Fic28lB_pgn3sWDvwlaN-EGXHNmCgWSV',
			6: '13Xn_IVyY92uiyc5jyGXyqUJgEwDiKjG3',
			7: '1rYtNTX3-Jt3RBf1h9V-1FALkL6UJwRKr',
			8: '1itHEow6TIk_jqfgZdN3ROI-OTxzOalQt',
			9: '1CeQltgsKwT1AhdTliDcway01vqet0RlE',
			10: '1o8oHGhbrgvL8A9zf30vvW47xM3b5jXuw',
			11: '1XcVJLbufNmirXGKynfhPbBYxzKZDlEWi',
			12: '1CZEOGWMwam8Xk7ftdVqz_6AagiXoXRGb',
			13: '1XaeXUirweO9tjxABIp1M0HL--rr0wtGA',
		},
		cinqueTerre: {
			0: '14xuF-2r6uv4ncZQkiP0BPR6B34YjY3Ox',
			1: '12xdduqiyuiYwPObqz_x2l4xy8KcI53lH',
			2: '1GZbqCYIpjJPHY6XisW4yi402WFgPQGVa',
			3: '1etQQKivtXWSP54Yrz4TeMNHZNMzk2YuU',
			4: '1JfHn5SdcJiN3m_13gkthhiKZTcbhNUgS',
			5: '1rgifYbXnMqiUVRX0WZm3e_LiCicvKfWr',
			6: '1ATxG70KvgEnidnlRMSjQwxMylCzVgJ3O',
			7: '10urgRvW1e8cJGPL9pquPq-P2gHkaIeiW',
			8: '1C_OKccQS5aJmkptUes6Xl5KvsqJzHsPI',
			9: '15sX9kaVtvjBnsAL3pSN7m4m76n4KamAh',
			10: '11TMowbAfU41NCxJ9dzinh3ZuF3qKZZ82',
			11: '1olAW5rJGYo3I9D9yLdoUSMGWeSiLQte4',
			12: '1sg_nmoVOsNIbcwbvkhQn4isGIiJrYw3O',
			13: '1F-SfK30ZpQDGQPBSd08i_VlRSaOcSQFk',
			14: '104pAVflvCHQBHvPnVsPSdGawb0E96jkN',
			15: '19n26sGxmlsPSWmU9TZpwjNZTINGE25rC',
			16: '1AENEPV2inzeVbIRxI4AGJsYr7xWZWOGQ',
			17: '1AczLL-3_d5-VkOc9JwKUVOyNPQgUY7qh',
			18: '1cG3yR1CD04BSipKy4jfmEQun_op653Mc',
			19: '1M9Fclr8xdCi5QemfHCiVOoOyvnxVHLiw',
			20: '1gCtC5qcq0fhXyLPwiFikPiKMVDajpagB',
			21: '1DBUtX2x1vSXMtddz_aTL7sSvzyyDVDF2',
			22: '1dqPsb5NcSIWRvl_lk5YRe09SfR39qWAa',
			23: '1nbtwUM9jpMseOA2Xw2LFXiczzUhTiFlQ',
			24: '1Y0Rjkhcarf8u2IiYIG7TfRrGrZ8P7A_-',
			25: '1Fpz1rSmDKobae-M_py832AZEHzS3VKNt',
			26: '1MCt1MwWOKSPc5qyC7xRIEAm-G3Ep0zPD',
			27: '1m8yw_kArNcxyDbKaLLl8vVVDIy9Bj4Tu',
			28: '1ed1CC72afKKWcMy5K5gT6Yh-I1Pm2sxB',
			29: '1ed1CC72afKKWcMy5K5gT6Yh-I1Pm2sxB',
			30: '13YlmP9ayRuVLOZiRot9KjaODPAoS4WKV',
			31: '1qi9QmuKU54HERa-35bUn3IguMIRw_ovv',
			32: '16tIpBm3V83IfB8Xew_d5NGVg0Cwg52j4',
			33: '1Hn7gTBq-JTodeFmxFuMroEqbcANMfRru',
			34: '1jnQhwCLAZ-zW_14ximFngBZO1EtL-994',
			35: '10LPJ29UiR8mRA_0h3RpfTzZzRbrZyfTF',
			36: '12quqMDjZpArPt-Jn0t5nmKLrBH5JDOPy',
			37: '1DvmtF_pmNc7uJaAnaCAp-AYtuYuPtZNP',
			38: '1ynQMtKJg5eCHEJpf8aO0YufMXzgZvVpE',
			39: '1rwEysIi40SJLDCtPD_pY4xPZbGfGZK9p',
			40: '11CgTBwpvSrYjuiNRQK-HjviwiftOsApe',
			41: '1HhvliZhQdog6__Gzp50SnXSHlnmrIM_4',
			42: '1u3DEjSS-aNjViq7-INNx_5eZmaXXPvia',
			43: '1LTSgj0xeeekSQn-SgJ-mjohaJfozo2UT',
			44: '1djGSqbK8na8HPqXispxbUFob17vRz1Q4',
			45: '13l7tT_wSBwZpIY8405iY16KVswpmhxRa',
			46: '1UXuYe-2lB6iueWyizuq62rKcQp0Y3AwF',
			47: '1fRjZulXpAQUIZkbSsNElxIqwaVIRbMHT',
			48: '1c5AMTSvCc7Azh1qAvFFRIkdLbbq81H1s',
			49: '16Dy93njOl0WMwM_UAm0oIiRk-SUwBD1C',
			50: '1Cc6rZV4snqh0PVmrI5gkSmJt1VceBuhm',
			51: '1MJtzJB_vVx7I3e2EQjXWxfdbWN5_KcQz',
			52: '1hwC60CvavWPyeRqBYhxS3QdeqaSdZz2g',
			53: '1WkHFksd2XChnJHK-aBLB5a3zEiDwWsqg',
			54: '1KuayP6CsKadJajAJ8Mc4RzXTxsu0NRYR',
			55: '1OWuqrmMSxiW7Qo83I9ecy2Agz2AlnkDj',
			56: '19X-YAVE4TFotQY0Rs3_1m0oo8ZDYa5Yn',
		},
		rome: {
			0: '1Xq6cKYvVTFSIAqoqQhIh3Jx_ihq_557Y',
			1: '1poDbbxi33G3tyFht0eJeTQAfZURsnpGC',
			2: '1jATLn0DllxZQKOSRuiVdM6dxhBStf0gi',
			3: '1rQnd_hiUxe3fUPs6hQ0HQpVZJYW66hu0',
			4: '1Mo0sT0o_3qmdWCIcUyBK0lFScZWUXMxr',
			5: '1eyO2xzLrgjr1kGPhcfWaysGssfuZPo9D',
			6: '1VtyJsiESIQsJXd1X4DnKcHyaP0X-dv5U',
			7: '1RsrZ_u5uJS_fduESNq3Arwi-gpco1RSf',
			8: '1XUICS4aM24j1m7G6Uur_s3rQgzYx5wkt',
			9: '10IoghfQDFYzlTgoOg-snLJAQprP4yb2a',
			10: '1XVZmYvSMizJ_cpr8Ql3hSu1SKIOBlu1Y',
			11: '1kW-XvhRqjcqFc2xvUndRbVWCaAtelh2U',
			12: '1RID7VL1igfisGQm3ECGyXT2jJQG6io74',
			13: '1tl-2dEZPfjHiqiG2awgNgtl4qJRkXmsm',
			14: '1PLiWL-6a46lqJriWNdJkG61IwYj3y40C',
			15: '1b1yCwRrUuRA8jJoPi0S725shNCQlhRit',
			16: '10VInVX8H1RbzBuh0lSYu-2FI1Spe0BKS',
			17: '1RzLumIdR9yM556z7AtVR7ees2vH0jxjH',
			18: '1z33njJPvCqIybh1rgxMxNwW7p2gI5_8D',
			19: '1uh1t6MF4UMy6GJsTOxG4IDXhHeFhJQmn',
			20: '1gd1wxbqdSQ0oYWz1bl8MWAyqNZMCzqTb',
			21: '1Cmjfb_SI2daOvVmDMhBm0zlC8gGIfYaU',
			22: '1sMBFqZPeFCLBBFj57snChaI9I7-ZU1b-',
			23: '1FMNFfQlbfUW9DuXkWk6auOlAfMh3acJJ',
			24: '13VrBuESxVLzrl_XScx45hWvhJgbVnrJO',
			25: '1NAI6CQgFfqKuq4yNyM91MlWj9tEcwO7u',
			26: '1rFANu9gu-rGnO1QzCXuJMT7ZLj4lFXsO',
			27: '1d8-7c26PulfSGWfEE2OrBte7EpQmvt9E',
			28: '1YCo7rWJ4oYx9bQ3nFw4z4Z-YnlHpyXAi',
		},
		tedxBucharest: {
			0: '12FjRccJvqai36uPCUyxutmXiTzapVvOi',
			1: '1Zp1iLHaQ-hwPuYOlzjUJ4z8DvFcW-VmE',
			2: '1yW8L7h0gzIelAuSfYpIot3B1-kd495oM',
			3: '1IYTzifGlraFmWFtXR3WFWczxs2Z5QYN2',
			4: '1xz52MCtcwfbOJwgYQ_0wVDjE0JCluts7',
			5: '1T42P0RyqTurIlWH7j5LEylkhDklOc35h',
			6: '1tgPRjcIofiyK2mnYXBp9NEA0Hxwos5Dw',
			7: '1UyCW1ImnfoP6HQEE8bt2V9yuVzSBlKNi',
			8: '12fBL-Ma9r5JRiqv07eqWFwKlOavsN4gg',
			9: '1hiGhvJiXJCee_Lbw-OtRoT_duBU9spxr',
			10: '1a-aFf5SW0Ch-dMRHMP7F9uPItDrYMA2b',
			11: '1N1a7XPB0wp8FBtb_wf4Lk7y4nFZFHm9B',
			12: '1BzHZ0-WrAM-osr8VsI36wiEX-d-MPgm8',
			13: '1dAqRxF86OgdjJqo8aptssMOkfuRtzsNB',
			14: '1x1KyxSVGsdzvdJtVeYEG44ZmcTgLhxn2',
			15: '1Iyzdt6lnDlnLhkCtQaFP2uK_kiPb84Zb',
			16: '1CnURM97YVEbnYAomlyqYHjzgNLd5ofRB',
			17: '1yFdPFTaALZpfWbNfiB6kRv1XXJp9W4yB',
			18: '14Bvis7c44FTfuUBWdYoxXDA1o4RFM5Fj',
			19: '1wAdDXVNZ2JGeY43QUHVIKZ06kEON72gw',
			20: '1Z9LgJfrtxxGr6vtRwFth5YG_KRbPznyv',
			21: '16vqCQDVz8ZR8w8994NVo_04ak6LNeuRR',
			22: '1hfCvjJWhDjgGqwalj1K7O_JlX8ojR3aS',
			23: '1ahrzo8GSaZwhWx7G1X6yzN2TSdPSAySf',
			24: '1jHt8OLoiiQc8Y08iGTDajYJgQtNVW97J',
			25: '1mfwhKClzSUF1cdmylXQ94RrPrH_1DPvD',
			26: '1ngNC8BfIAGscFiz4535BLGum4uDsuwEk',
			27: '14uIOcfIMB4BHs58EmudlbSEzUIrYPAq_',
			28: '13ksZldZ7SKGBJbKJPRvAS5ZlG9DxfrVw',
			29: '1s8aMPBbfrOBSbi6PmUXOusgGIc3rau3S',
			30: '1hYfgn-MpTC7jSK61JfjzAbVMsfd_C0wX',
			31: '1lr3mZ-zhDILqGNck12yZzLA09UO5uRnf',
			32: '1pg5aQPmOJALB9_DI_JcZ0vGF7c0ttola',
			33: '1jcevZpubDdLRWt7StfcgR6U7Rd09Xlj5',
			34: '19R2Awnbbr-QEA6NZbvnOogaj_KjWBn7m',
			35: '1VcfP8z-XrGEntcGtJd-QtxdaqWfnQWfj',
			36: '1lZ_Yrj7iOxNzJ7CywaUcRtT_mWpGVc-p',
			37: '1pfWURV0m5TDRZ5Jtrvi3Y0wBWcrwwIHM',
			38: '1eVlX-WZc9D3_NB5auNb-89NRaqL7-rJ8',
			39: '1mR_xLLu-qNMc2-6Zr2DlcpRfo_0Eyxn_',
			40: '1Es7vyBXuYAqtE0yd6geCBvkReQBZCbtI',
			41: '1JdZavGZGu2d_1liXH1U-yOh6petbzqJR',
			42: '1nZ89KCgZ4LSVFg39B9jp3K_MTkvOmios',
			43: '1ZlpCUtGG7SQ-weoXGQ1PQLnTFqHQ68_F',
			44: '15xGVP76XgOk1Cu0No80XT_CfTopZ4tS3',
			45: '1T3wGB3vRORGoJu-W258q49X3OaAib5mS',
			46: '1vvlsD4m0t57zjrwWCTq4Jl0LQvQGpqhn',
			47: '14ucYvHCjp-XN-P1E46rZrx3xeRy5Q8dG',
			48: '1K04pbY7j7CfCgLUJ674Pqzx0W2a2_OOX',
			49: '175sDETsX_XrNiyv2ivfWhOTO0x6nAJFg',
			50: '1dwyO9fk9nVeO9MUApFGvC8ZlwpgGma41',
			51: '1XAEsMc3-7slgk1i8JcaWsnZsv6gfgBbB',
			52: '1Xgy8tRjs0E_HB4Onn8w-C4hTbENdi-WK',
			53: '1i_Hkmc-phdigEtuKg4VzY3x4zYvjTDtz',
			54: '1J87XYnp8aa43EUsaCk-wVvqG_dylLIJb',
			55: '1g1GXwmZxYKCaOHcDBlzLi3_J4Dpyfv9c',
			56: '1DfQoPOFJV_bXH2clIap4mA2-tP7aygX3',
			57: '10CD0WuwuBy1YAeVGQ51KhwXhES-uoQmg',
			58: '1kQ-7q8vVLnnFQD6dTU2oxgwiJlR16Nx1',
			59: '1r98FhT4Qs_blFJFCcFSJ7267xidthaXv',
			60: '1q2KNFNa3k9O55quC8eMuzr4MnZ9srN7e',
			61: '1LtTp7iH-xKlgLS4hZAw5p2sz1RWb2VRO',
		},
		tedxPoli: {
			0: '1iN84G3UN2Q9aiRHRmTtjLrSmWpZFEXPe',
			1: '1y6NTKs7epgqSx91HLJTyNt8d6YnkAA5L',
			2: '1b1insvuaNZ0IzNIlBaaBAZ5C6twf3a2O',
			3: '1zD7JK1H59eVZdjki_9utcNbWSq86Q7Eq',
			4: '1Fj3gNbEJ9o6feHj7ZiPWDCZLkNhsDxMJ',
			5: '1r_KIgqZmBnQy2uA3pwv6CF84qNEolVvd',
			6: '1EfC0wd7TUzTqq8HioB3YAbIJLmdBysgR',
			7: '1-_Ax13zUPTbmicvqN38kKUxbIbxFp8ZZ',
			8: '1UtthhAgt4_9gLirJ2HrcImXSv-XQ9WjR',
			9: '1W1FpDzFIgHVWPS_S91TLkHrZqmEtZjwZ',
			10: '1KpSNMCfjpzv_tH6T7GBZxJ-OZ1wYk67U',
			11: '1lWS2cHhJ4ms5gdoZfrJm8fCr5doFcOig',
			12: '130TAgM-4u2rfrylmK9vTKNiOsP_vUpq8',
			13: '1BsiMJfuPrWVfSjA8Lo9SGNUwizT5wB4t',
			14: '1ja8vTUc-h_Coo2l2DYaKHzVRJINMmK2z',
			15: '1u0HDm4iDmm4MneF4bioBj1hAg_wSzcIM',
			16: '1uZXsF5WgUFai_RUlSWBPZrnuE2WKyuu3',
			17: '1WkN82PnXcgJB0-nMuJlFBT9f27ZuFMC4',
			18: '1RBNDrLWVYEqeg2KbkR_PClL6nTOA7Hfs',
			19: '1xYQGVOZrbZW-Wge-pdJ0_Psz9qmeEGsK',
			20: '1RTPlOvbtVlhBYHP5HxqhIcdg9fbUnvhL',
			21: '1r8CifccEubdTH0Ajb2mQWFF9-c_VeWLq',
			22: '1By7QSjW1WBovg5V_TeOiD9o99LYO9ci0',
			23: '1mYhDovH_8KLHfG168GmKe5qGHPCnkS03',
			24: '1iUyRRgahQCGymKzc6EqHAgHEzXTINMu2',
			25: '1xUZxTKOLlmHn5xe_jX08SWDHC91iGRHO',
			26: '1KO0DfsR3GoS_wX2LtL2wDjsrdpLMP0Wc',
			27: '18q8t1L3LCaqVI2hfjVdX1hSJi7ZuOr81',
			28: '1-yX5fxraXa7lDha8qd6ZiwdJpvnKfx-g',
			29: '17gkGT7wcnkL-UM86tkQ58HnNxKy7KXwq',
			30: '1vTw3YFa8W9lTCH67ycWLoNluQAiNNxRp',
			31: '1fQHdbsnd6zXEVodb8YzHVRcIVTZHIKgR',
			32: '1PSUbxgOUgG8_TveIbbMjdVowXItACdHm',
			33: '1kmeGheHHt-8Y15jaZTscbl_PoNQ_0P04',
			34: '1i9Qk7J8t7MrHJdbmRL9sbOX6RBdIFtB5',
			35: '1wG-F4ZeS-S53tfbUryfK4dbK3-L0IfSn',
			36: '1th5dx7XZl--r47wocDvGu5aFsB7b853N',
			37: '1emZ0-AUto0YZ4ptvTLZGi-Qiu3Dr7I_J',
			38: '11f-ehrC40YoelOhMy8usm3nptF9M_OZX',
			39: '1w42DUEhdw2Zu_qWm1I2Nlf8L8LBegZlt',
			40: '1w6njvViGkz2NfhM6aCWtd9lZ5ZK-WhOl',
			41: '1jhLGj7FDDdIUg2LLePPdcseUCPmhVyQj',
			42: '1fisliaEe7HUdXgDItNIsvHH7SYbskI6Q',
			43: '14N1kQqDUc5QEo-T5AKfmJBWVNIJpjtGD',
			44: '14GgNcVDkIUwPQj5V5vgpPN4QmH30prdf',
			45: '1iYEPAh5UIiQOIaVQQ4oPrArhnVHwyJOf',
			46: '1ofCpWQykyWoFfhXpEDkwGB-maa5KSbeF',
			47: '1LC_qu4LKNuEfDXibz6dxJrvy4szL4Ook',
			48: '1vv4vP0OApOdMbM4Ie58x-dkSx4Poo5OZ',
			49: '1Wdm4A5u4YmTYE3eY5eAnuSTMekjdQaco',
			50: '1GN67YgTSzrWq30c8bnJfLoYM8G4Dxok7',
			51: '15AN1z93z0IqP9fiL_81JC6tMfXlo72R2',
			52: '1s0fNeKWMooTlKidkJNdN5Zdv5kafHvH6',
			53: '1orIIoo1dRHBDLy3P48PDfrJLgcbWGbth',
			54: '1LHIY76zzZlgq5V_-L0cIaLQUBVWcn1Qs',
			55: '1vH6UqSsmfarhtJPrYl3xvYplduUth4Vq',
			56: '1BoTS8esz8qmLCmSijC8uK9VRkfhdPJI3',
			57: '1RWQHilNk_3OnaETsdCl-e69JSfaG8aYW',
			58: '1yzMSpYtb2wbijPLg3NQM1xO6fqzOmyMl',
			59: '1hXx-Q7sinOTXEt4sFw8a_7Pbbz8Bmrt3',
			60: '1H6HI2Z4hN6vRhGUapd3Mvb-II8-IGF0x',
			61: '12zTOndgKOfGvPXEcafuFCnIcZlD4Y9NT',
			62: '1RcPc5341eIHLm1h2iFOuNoc0TER2s2Xw',
			63: '1csPWA5Bfywm6V62X5H7J0QmeFBGjpgtO',
			64: '1xjyh95BtDpRUO9v5FoVxo8f0okLFwRCS',
			65: '1-sNX64qROVLRf9ffbojGkQhfOYifIQ1c',
			66: '1cdBr-phqSNnk6h2SxDzRGUrQjgGl5TaE',
		},
		eliza: {
			0: '1s5ikdxn4okDUu65YmagI8d6snlq-wtY0',
			1: '1utoMsBizjMHBgSMQXJLuIcnrDd_wEr0A',
			2: '1GChNTYhSwbdL6wynSMFj3dyngeGLEnMM',
			3: '1h2Wa2DkXg7PcD98q9G9TZon_Ap_N1xUI',
			4: '1MwKZSgmeXOoMiqVMk_MrNBHcesw8zOde',
			5: '1Ndj3nQtNELHoK8a-TXPmUzOZj50L4WH5',
			6: '1KYOcIDcA6EhpRbHJ-NtSAhDHfjZL-aS6',
			7: '1QKfinjebSpJsSRNGLXUkM2yYzF97aHju',
			8: '18fgPqdQihNi9YGWDO1zDSloy0VotwNcg',
			9: '1B9-QFjE21KimTvB4dBT559WZbgYN_Ak_',
			10: '1o3MsC3_3aG71nv1NRTFGbZrzwD_0YeHE',
			11: '1esDjdljhFNvQR0WX_P2CoWN25S1HcZK2',
			12: '1vY8OmvuxkNnMraqfTJMsfBN3_mdauj1t',
			13: '1NAwMEoubAauIESdzXF9aywGu21Y3VC7a',
			14: '1SkobzpbwyzGM5JsLM6aywnAzKGhdLy0-',
			15: '1r1Mb3-h2XAzGSTd6P0842Rr5MkSr3HOS',
			16: '1Oc1q_cH2gIcJ3uKIpubyW9NB_BvZtF5M',
			17: '1xPlPal_Tp0yeQYUwmX8NbagAyxJNj1mh',
			18: '19yLVarZ7yyDuhGqUdZJjBWxEUVbQyHFj',
			19: '1cw6CkjJNShrEme-V3GhCw2FWiD0vCfiy',
			20: '1ER3682xjhXQNziX5AEM7QLxYe5HI8H_o',
			21: '1IJe65XKgN_dJkOnxtL31PQy3vFKSzr6N',
			22: '1xVj1XzJFlUluQEyeDlEMwpc2ypuf9fHr',
			23: '1XmbVj5BhTqK8R8EZe7pQr4qg_qjuSDu4',
			24: '1d7arG2BvPPUqk64B1EbI9SPoN9ITFapo',
			25: '1aSNs8p9_3yZvXxjZtToUgkNf-WZh63kU',
			26: '1semhAkqWFNlldd6FZTE_ZykzgzcztlYi',
			27: '1hpKRtE2jySK_Ims9EvvoVKjUwO6OM71P',
			28: '1vL-5hHpUluBpIBm-Mh5ByFQ-MzBye_hv',
			29: '1c2gGajPZnVYh4FecS5VUmAFyYnYcFbMT',
			30: '1uG8TthkWKlFlkOPm770XhNDozmdx1eUW',
			31: '1OK15lzue_UvFZUaaUU_sGhZNCeodtkt0',
			32: '1aamy9tTHaSQNc7cOCCBwJMEeRDEu0PLu',
			33: '1qPWMs6VNAJHenwG9ViO6rYwL6KWNEA8A',
			34: '16hDZAKqRLcl2pB4fb4HtfGAZTfh7tyw_',
			35: '136zswcHCUOucKGjsifbLCqJRkQ7UiqlH',
			36: '1NpEhSS5DmdM5lxQyX1kRc-l8ZhAWlshQ',
			37: '1JgwFhYO-gpbzZZlXPZ2wZfAvN_lEC-AK',
			38: '1-R0Zq3UJeO5Lz--Z_758yCAJDvaXRukQ',
			39: '1D3ivnYthuPv8yeM82bzZWzYJ61j-X_z3',
			40: '1LZrmMy7qB2Y-jUoP6Z0VO_XxlDdGltxY',
			41: '1GplJ7ABkOiFrwHHBSbeiiGtlKE1L9OdW',
			42: '1F_mWo54tsVnPzKJj-rgNR1GL9Ep7HnTX',
			43: '1YLMKT-xBK7ZWTm0AGzvA-EoizAb4XRiW',
		},
		luiza: {
			0: '1U-5aUedsTb1ENgvxQH8OON5bonsO3cEX',
			1: '1JJETw_S7cSTTLHWe8mtkR_D8eAYiYuL7',
			2: '1b0R1JbdDnarQy4qolZhtMPZZ1Zlq3h-9',
			3: '1m4ZB9ue9H2d5MPowAyJoYviAqvK8odQ3',
			4: '1UWIRdP9vFnsbFcK6Ril-ykVv_BLVlPZC',
			5: '1AHWUAPJy6qtZ7MfTExw4rlxsxhFpMNPX',
			6: '1xFCcKdls6HqKYib9VEOz8BVi_oWBpdvN',
			7: '1AeiZ0tel_-Pr0ysTI-xtAH_xGcTu-rC7',
			8: '1Ysl9xXWkB1Xh9YtKEDCe8FursOqvhMcG',
			9: '1UjVibJ0j8KzxgNI_xZlZY2towIWf0l0g',
			10: '1fMQJZ2QYzr74U88oPJQs9RSKstFmisZw',
			11: '1RBSyfjr8OR-uJzHJBrk01Rf844feQGrP',
			12: '1XFpL1MNxV7V_JX2GV7ijW3PwDbBKm-Tz',
			13: '153yXACRFo8owt2-MJceWYE4bi95uuTdq',
		},
		marinela: {
			0: '1jfslBa87F0jtRBY5rjP_wJmmdVPmr0A8',
			1: '12RGQptld1iN1uAMSqNIuvjcaEzN_gg3d',
			2: '1SiIS1Wrj4twcw3ZJsWsgI01Y-x9H0LMu',
			3: '1v267XSQ7nz7g_LTL4W_-SzU3sPbfr8p9',
			4: '1ldJkV-BcVlt_vkZCP3qCehlHrWLnjeyR',
			5: '1hnCeASPZKo_4yoNVjfk6XrI2ysDjrmNI',
			6: '1spOUe6e7jRB5a0G89XGukyldEN21VZGy',
		},
		prods: {
			0:'16rs2hqAuoqhtJRTRyk5IKkCk6zq58uMV',
			1:'1-BxRYGEFSUy2cKP7lpgg2pQRUUq8GfpM',
			2:'1RnkUVraltn3jfAWRuOklY2x45NWH7ZKs',
			3:'1UR6qAlSVoysSeSiSCg_DRlx7jI0W6ikU',
			4:'1MfyUzGFxH0_J0hUe0helQJgifVxV-8j_',
			5:'1nq9-Bx0eJKkOlm9EOi9xWBxLYjLMIcM8',
			6:'1N6r49ypmeIIL-4p6eHJjPD_p4q64UrKm',
			7:'1aEw_vjZ7xIVWMHLXK8KZgHpwzxd6yRVP',
			8:'1KCAJxoWZHXHiFezXJeQMx80GZ205k64T',
			9:'1H_j_XWaa0m9RCrkf3sfqjuxWokscPZqS',
			10:'1sFzI402k12KJn5O5uvTl-NXYt7i7957c',
			11:'155z-6-pWGP68udZcEVVjl4OZvxYLzihO',
			12:'1sm_Wsy_wWODsuMuGBJiPB7eicK7j1J1p',
			13:'1lQHYbkaYZ1YwwZx0HdR8_gaqSsKjp8du',
			14:'1cmYglz_UOnCV30Bp_JOKy719FDOW1cqw',
			15:'1Yb5XtOigI4iumbiS0enO5nKiRgc7tYyM',
			16:'1MHA5kitWdUGuLZTNnkK40Pw-SGVznHT7',
			17:'1Wkj7L4pvM4GxW1mjEZB7iYjqj4ZtOJtA',
			18:'1_xJris6pLMFM5NRSjQANrOiDUq6pEFqf',
			19:'1mBk8vIRZ-HMb9of4-8VBbVdsujO0NDVe',
			20:'1X6Kd0h3g2tQ6wwCoUxb8y55fbz4yfmmi',
			21:'16YThZj-ZopEzD5IsLQlYq2xE0ZVzM0ZQ',
			22:'19nrUkq97hvGbbvwyk6LjvrvV2GcWEOd6',
			23:'1OVmanx2uBZ2Ws0Vfbz9CPMHLfbW041ze',
			24:'1ANcxb-IQ8PXxXLfhU1nLYd9DgDuxEjzw',
			25:'1Lk2OjjUc5FEdk3QfzZfOvVLhfigrKjQ8',
			26:'1d2JMXsBjTcEnoHdBZpuSF6gJ-aBAfNnV',
			27:'1UiuU-9MLcqReBvsu04RxaZny7lWJEcKs',
			28:'1R61blufIzV2yPYCW6mOnbuO6Y5AABah5',
			29:'1ooRU13hf1gGVh13X1BE0hYiV0l3-GlzR',
			30:'1zUpbqNfUQGYCFvaAJnasxQppWlTsO5mK',
			31:'166v8jxXYsdYxW9H5ZOJ8cHjKBvwmQhVm',
			32:'1fWmsR8v5w8eKkmyTmNrkUw2O5P0f8YIE',
			33:'1V7aL7rFGxGhjM6Ja4JLwb8oYPps7Ppbk',
			34:'1nUc3WJcK6DBjHr6jIdP3A1_4em3JEZc5',
			35:'1Z4FBGOLyf8UlYk2iJSE2pqQnS0HgXPwK',
			36:'15EOO9g_1dFsf9RQF6kafyGs8SPGwAzP0',
			37:'1L0sDb4BLxHV0o4qgrFnvkAlBc67djv1D',
			38:'19jlJ5b8JP7H1zbbRnWvzNYurOhs-w4uT',
			39:'1JEizhChrffqG8Ef2aTH8fBTFa-iTgabc',
			40:'1GzAQpdnuSR5a7na20a360V22ZsWcflTq',
			41:'1l6mFs7M5I-lfeiqoKiK-nmJ-j7DubRKd',
			42:'1pYUdOq6ZvA9p3xfAKdkHPP0JxLcYYR5W',
			43:'1No24Wn4FOq81f4Bp6oZ2g5q1_75xnnHj',
			44:'1SlHTkK02aUCFN1ngeHbDdyGjsbSizS13',
			45:'1xlWvYHlwKc-r0NBpPg-aVkvBkJYI2zRu',
			46:'1b2o-Bqyf9QdBdArU0zb8LooQMPX6Ofl_',
			47:'1LnoHO71ypxpUj2vhcNsb62cdF5GdT1PM',
			48:'13NFirfLgMbNBvNWeF5XPZIG6yCdCPK7A',
			49:'1L7bbuD1qFPe2qOPCGseu-heIwaDX_RAn',
			50:'1HcFhz_baNJoq57KuBc3Piuy_bP_4PCdQ',
			51:'1Gb5sZ3xuArRe7d6Y7ZUuDDr1DEut1ct6',
			52:'1764FIuTzSDnD6OR4W3StWIJPPJnqaq1X',
			53:'12BzmtZyN-s0X4ZJVELlZnQbvuxgrI-f_',
			54:'1N8Gm03p49ggZcqYGJj5GsUXggoJAGBaX',
			55:'1S1BcXYHyFFOb_X2_zR6j3jQ5i5s2Fe-v',
			56:'1KSmAwCk_6Pqi7AT-FPTfsi1Tfn002Yp4',
			57:'125PVMC4ve2SICybmDSDCPoKFV-UD-AXH',
			58:'19i6lAfgt5nFB2jx2v44FC_TX5Br9Z0i3',
			59:'1Ir_79bMfBoARfXj8aqfTha_Fa64ezrGm',
			60:'1KL_NsvnQmUmNlPePrL5nc12cDms2bJAO',
			61:'1BGwFwKw6cx3sBrt1HSY2LTvWJWm8MxXx',
			62:'1GgT-wuk-lTJOx4sfurpkcYjdQLfPerrc',
			63:'1MpiUP_cD2jbHElH5N8KhL8jJYraJgkI3',
			64:'1bUq_KyrXCFL-KDfdFVZN0N86j98R3YL8',
			65:'1QMRMBQxSpr-eMfOe6dIKbl-IH9vap1t1',
		},
		adinaAndFlorin: {
			0: '1U40XqNExaITQJu7PKHDIWJ8uUHM-Igou',
			1: '1_ZjnHXnya8TNQxHp4CsRvP9pUhdLXNYk',
			2: '1Puswf5r0HW-Zzi5EPYf5OkUk0kaL0ZlC',
			3: '1ST-Da9VDi2g95BWsCBTh_S9fXfRpaUv-',
			4: '1gOeg_eSXJuZTG3vy-veURpGkVGeufQ_d',
			5: '1eOx_V12xPwu5Z5sjDWeQicp5qZGT-bIZ',
			6: '1axQ9_F1rvn8kw2K7EntC2rEWoYGDyn7f',
			7: '1OzHfZINtgsdi2U134nNMc1pQLQ8CiZGO',
			8: '1mlh6WGdpKCvMh_GR6yxprLnHl6Tgflo0',
			9: '1EiZ9Ug0wPqn-N-3UseActytsvH7ZwW4l',
			10: '1vn2IAxyf-7Xop94zkppJcQSDAqEuz65c',
			11: '1Ux2L2yMzgqJOmbCiMOaJU7Y1Nd9eWfO1',
			12: '1E7ORjbME79nvqGQJ3PPhYxbLzoQI_EfJ',
			13: '1CyhQOjVMlvGxx4OO76ngivZijTzyWdJ3',
			14: '1oTy89OCSBY0WKaTkzo_FRDQLKQjvqlaP',
			15: '1pKXbeFyqX-Jgdlrh5tsPbPmxTNjgJTtT',
			16: '1_BdU9zX0eTpY0nYFtnQZpl2Mw3zPdOC6',
			17: '1vw_q8JsZt3C347_QlAnPUiAb9sCvZZcJ',
			18: '1Wnd_vaVPKadaH4yPFOezKXwKjw3MhXW0',
		},
		andreaPregnancy: {
			0: '1f3t7YG9eYGaTDHP_2_JDsnRjyJ-yMXrM',
			1: '1qyDnpv9_EZhjdXCcjphaI4oditDRYgay',
			2: '1ekqrje3pZgBqt_hUc1aRVMZn5adEN1DX',
			3: '1GI3rSUJuyV-MOQENyFcJCAF2keO4lbkz',
			4: '13INovwnQ_m_plsZyQItAp2j4qMv-gnz5',
			5: '1m8fxbvmk_C455wb7xgTlxG7y048l6lkX',
			6: '1eH_VgfLXrnGZG6921KSo9L42trHukOzY',
			7: '1CLKL3Dr7zeF-KBco2i4w5iZGLpWv9mj8',
			8: '1yZv7mapkIPr_Iuw496ewkNTwlSsxbIKs',
			9: '1xld5RGqal62IzxqnPWwSlddqTrESF8Vh',
			10: '1IY-FaQ3q6i5AcAkPos6ZYHo_wMrDGo1-',
			11: '1GMq8SP1OLl-6-KBuulBNUlKn9Jk0y7Yl',
			12: '1aND1prIq5wTRDhRP-xROX8-9LGqzFdUd',
			13: '1U7FVNtCxrZR0JUdtV8RvVJdX9ie2Qk1M',
			14: '1RlV2fG84DgtCUOz78Xc_HlChZSNXtDcL',
			15: '1CjX-Eg7GoiDTlH8yHEoFc1hCPSxBF-GS',
			16: '1elfjNawTd0Lu6urxlKPL2tb6q_q7OHEV',
			17: '1f_qOvJphHNXYQG4XHCDbsIJ9hk5X--q1',
			18: '1Xo2QKU02o6qB0-eY_BBnddaMkoRyPC_A',
			19: '1ZSsr0b7e04mFXVsJpt6kfjwcO7hUMM15',
			20: '123xDzyUQSwKVnydMTo0jh7cDoNgJff07',
			21: '1mTszEj3QTgmTKsqcaEtNWCHTsTktC10A',
			22: '1dJAUa0BkwDo58fk4qIAGvhJrqoML4mIs',
			23: '1t_L7CviYx6EFwKz_7BomFFRws4V18TMu',
			24: '1kZ9vjpmoo_yvZYSao3omXUgJDK-_BrK4',
			25: '1_vCFY2f2_AT1waOnCrqEPZjMB5upqLrQ',
			26: '1OMJveAjgX1XW0ZvLMCIZ9-tQvX4xHpEK',
			27: '1ICQB82mCs4m8dTAC7Xc0INn3ISiX_onS',
			28: '1DFvnV8fEKU3pLHjE3SbrGAb-03l93qih',
			29: '1j2_gXWRhamH1DCNvwlLUVb95ysC6LmfH',
			30: '1-zK4IYu5_nrx0fpsAvvRZxOk7VDdArPx',
			31: '1Ie47q4HmLVqqK7DfvKo6dfzBP4ArVI4n',
			32: '1yiUHRsn4xNUhH7D5xCpO7DDUXdG7f6xA',
			33: '1d11PRYrEMu6XnQ8PnipROc-v84L5MnNo',
			34: '1n2FQ50EWFg4vCJ3EWkmMlRrzNiNhznHr',
			35: '1FEC5Oa3uYQD8DlFLe9jRnrAKARudJ63N',
			36: '11dALpzBHUhy0RGw7CbcvZ_VZJ7XCMxPx',
			37: '1son4FFCAoxse3KDBV6cHQeDVesN3pxZ2',
			38: '1nRWVcXsTWRz5fjRmpxlZlLulPYcUpBG7',
			39: '1lkOESyIY2x1GBqjJPVU2klVD7kRls6AZ',
			40: '1As_voK79ABVOOQKys93WmSTipIM8-O_i',
			41: '15RhUaoMMRGBPJfn5ZWWYX8Myfb9Z4zRv',
			42: '1fM8emqNrzhInLwqEaUTzoV-rNxemXywf',
			43: '1AR2Tv4HJtDZi4Q5LPL80e7nBbfH8-HPZ',
			44: '1SG-uRwxnta-c2MnzZDo6pWuBPFrmYiuN',
			45: '1ZC2-WMHJLD3abZx-fJHCyRpJJeSW_Fpn',
			46: '1QoYXP-HW9chk264jazPPzob6AHaX1ln2',
			47: '1OpjsaVg9ufnQ5R1iI4t5AJgnyFf4ohah',
			48: '1MgyWVajGwoSCPPin7-mFodtJtYKVRzkU',
			49: '1ehNIaBCqfExVbC6KqN2DnueYfNB42ZVo',
		},
		mariaBaptism: {
			0: '1d0AStOj5fcd_U-39134eHcZkwFiLD1UX',
			1: '1ozI5GB3wKjr4ORH-CKC56pDU6q_TJ_wu',
			2: '1lTm8T7EKx52fvwRqo3YYAlJPsYOERc7O',
			3: '1Rz4yja8BJEZhU-F3oAnyskiqFGQ_KdHn',
			4: '1BX5DuB9AOwaexd7B-sbYh9vaoc6RC1pn',
			5: '1mblwQph1qhDiJxOh1WhDYuh8G2py6G_o',
			6: '1PaMxHDm9DBjxtoJvjz_WkxtyKgtqqmbu',
			7: '16JHgHdULasA8yXFEnVEd8kyLXElmgTVP',
			8: '1VBPHYsbDgKdo_Gh3OPykiZkPBUsY_Lzv',
			9: '1RVdZelgDE6CY9giLC60Mhxh0I2usiHQm',
			10: '1XTMNtwTto3OMDR7CBPE85Unbss8W3vK2',
			11: '178NBjWkXojdO4mnectReK6sBZ5Pd4L70',
			12: '1KzWjGPEZqalw0YUIcdeMQdH5f1v2YgVe',
			13: '12TGtepluksVjAeJYyVDhnhbgMxXkm1o2',
			14: '1Qm0WKHRCixEdrWOHPUMTs9pGu04Q0boL',
			15: '1c_inDKFrylMf7cv7ukvdCxV8SictKjTk',
			16: '1xZ4k7mlJUJFEissolG1E0Uzdx1ZzkpWP',
			17: '1Ee3vgMGpwPKGANxhgZnPOaDxI7nhzoXx',
			18: '1PYAlfDwVoXZuMvkj-eIHHUeV3EkwrmED',
			19: '1LvQ_kRoLvOEwe-EduVsGhjMTpZC11mAF',
			20: '16AYjZwBZ3YrD31sknzhJOq_k5VpBC2Qy',
			21: '1iWD4BFaXANrIEClGzur5GC0XNWiobOpT',
			22: '1THPwdVKAlOuJnntik88eRWdIOJiDCCMO',
			23: '1GltJCxZ1169J7HudrcICFClIkztkAbD7',
			24: '1kOt0vXgsoffBz3pXUM0DD4o_PI0ND8Nh',
			25: '1g6kUhgmIQCYN1aGdXYVxvoENJQ4iBHpQ',
			26: '141-t7PAkHJUAIMKS_NmgiZdhyCgZaSOA',
			27: '1X4t2rTrz9b4RG5KLxquXXFFEGNjlGE9m',
			28: '11ibwJz63_b_YT5dEepKC-wgmfk2NYQ45',
			29: '1ANla_D-8EeAwYC1_qd3ZeqiOm6YIgt4D',
			30: '1V1DXbVIGabY8wEYZ3QBFxYNLuXFgZjBh',
			31: '1yA_SynLP-hdv96O3mc-a1-Sq-NO9iZgQ',
			32: '1orAsS7Rg2jHSwy-wzNcIZEOizp7mJL2g',
			33: '1MNG79FP-FZYxlwH3--hYd0ayFo5cIQ4o',
			34: '1EQI2bMBHv8W3j3_u0-6yJjSKkZpHHWnY',
			35: '1QW_7FlATPpID3xszGzKPgu8E5iS23ch4',
			36: '1M5hTol_Z2W2mjN0CFTh-JuQ_RVXv4V_h',
			37: '195CbuQJJawPg5jgWCz8Yyi7FF6D9wxCl',
			38: '1b_vGOpvdrzJsol-zcCG_4VcAmCWF87Vm',
			39: '1cHOnFt1iiuRhWF175aCl0B2imM8xhIEL',
			40: '1UJcmSn_5H0yIFOZnGMISUpDWFabmcOrF',
			41: '1-RZAefon36tCo7I44xxDDLYJQ8k5fdZb',
			42: '1pf9MJgx_SsoxCwpNziF49HvJNfhtYhtr',
			43: '1GargY5hGh1BvOtD0PZCThEsrYffyP7HX',
			44: '1MPje-HQC73ibYcLWl_zuHUNtDRzDAbnA',
			45: '1lV9fQVYGGbz7wMbXh-leoVMn7vlpHucw',
			46: '1O8239kQmKpEfrlnlXk0ZMmXvKONtYBrs',
			47: '1Jqh9HTsgtHg0nPDEIqxnDflfSRVoYuGU',
			48: '1UiRSpjNBRgJWmZDfcQ44jwCBlYbKJ-EJ',
			49: '1A4JFGtgfLi1myiwcueXd0VAABjW6FGr5',
			50: '1Lo53FTH6uGByV58WlI7ZQuuGXKlpa2OJ',
			51: '1SfiitWHDE37m6SZ3OlD4-cfdLh1Bv_PD',
			52: '1Btz624wV93-wBTKdOLxJ4FQ5dlGnj1FY',
			53: '1THXW68PSzCNa5ZfEJwRKaAtbT4TJyljw',
			54: '1_t4-n11t_dNtPTOnO8m3S2H8tO4Me83M',
			55: '1XTYVVRW6J0j-30Yi-ZSLzuazUrobbnk1',
			56: '1X3oPBBl61Emu5M3I1RicvMZB4DdKbAhi',
			57: '1OWsd-4LmYMxZF-UJZesiB3SGmHGXDpGv',
			58: '11hPLZoWjOdxFukpmH2B2dKqq4uoZTGwf',
		},
		anaCiprian: {
			0: '1vbyr6MSoEHtLAckMh1cz5y1Kc4BH-nmG',
			1: '1ig8WRg1Kc0anUdWvJnU0MoXEkHvfaW4w',
			2: '1WNIG1681FBuMdaLT39EwB9d3XPmTftBr',
			3: '1a76_bndbPrWp70x6YB3gckUgtXKpNT1x',
			4: '1tnXoufujP3c3v1jC28mmQWbhJjqimjeR',
			5: '1D1W77taFVBkVyfjVjZMq1wAoseYdVkoC',
			6: '1BEU3NmRx-7JbU8fZFHDTqzZHgY6y-rnO',
			7: '1ERIfHJVZ2THLnxwm7Jxv0q1RDdU8vvpR',
			8: '1g4TkqoWcmjFp9PmXT5ZZZS4SX5zZYICW',
			9: '1DgamSzpYp1Ox3cQga1BNOQiDiE_7PCUu',
			10: '1HJYIecQjVaujxIqK-XJAtgBG6Zzo9TK3',
			11: '1PRK2-ZxK7eNAxUHtAfuezrokorse2Nni',
			12: '1lz2GzTHAXdnOoSjd0DXyq0yv7H9g9EOH',
			13: '1-c6VWVHiy1yoNiVSvmjAz55IP95ew15M',
			14: '1pCpA_-OP3fqQOKIlGaB7-axLo9mJkWnS',
			15: '1-Cvn1txD_bgfKS_limUvaV5FTmHCrUHe',
			16: '11pHBBr1iPxQ3AI3D_ch0aS0obfLaKySo',
			17: '1l1f3F690_bkhZLZummm0K-zQc5VR-uT7',
			18: '1_Z7eCz941Ga2_EMcHIvGA38IqYprX7ev',
			19: '1lnGUU1d2wVJIkrbt5dj3EDnjIQXGTRIT',
			20: '1GsmwxR75LDsdvq4df0V_X9R2BMiM1mqh',
			21: '1FQgC_BW4WaWi7GjeeJf3N9raYwblSum2',
			22: '1JFntlMiSQFCdU6Tugrk_md3DQZMdYNJS',
			23: '1gs-4PmphtgbqBzQ7kY0g_GMemlpzS-ho',
			24: '1GAmzOVULckan2MVqlKHOSbEqtmPKKyFG',
			25: '1vaebsDL2LIKH5A3w-6jlaIqwX4psG2lj',
			26: '17HdpKliXaSxaQe7RQwwGwWW-ybSz8ppQ',
			27: '1cI0gAHwZEYo4cIE2iCKyZzNcIq8qLpBK',
			28: '1TT0S8teT2RtauaZD-sZi056m2L4qCli3',
			29: '12IU5y2l0tD7AWcRTPoLpsFHGBsS-slVL',
			30: '1Borrz4KDQ64Un4OI6UKJSI8Ir50Cbd2L',
			31: '1ZsJ-ag555xRSMzyKL48Wp1aJGbDINjk3',
			32: '1OvReNGP4--kisSrzuSVWi5t_ZmyH2LFm',
			33: '1NMGDLzqsKfeeFN4XPPdLDE8v4G7XgMs7',
			34: '1a3338jX2I_YP8LZUn_To9iR8TVebAN8T',
			35: '1hv5vDR188_pRpquPbCjqzt7rnn67n9KG',
			36: '1zUj1w2-8PASJGrsyy8o_oeVl4xxRz7SY',
			37: '1K4gwfnNhWaouyfCaIz-VOIXAzyWRO4MO',
			38: '1Li6rj-DqV-efh50kzMJUB2id3JNml5ot',
			39: '19DT4VP7USowTmXSXp1AbzlOTwnRD5sfv',
			40: '1NzL4XSFn3pADzgH7QDAFsdAuWh9Z7gN9',
			41: '1obZvoqVxXhDTMupTS6WfFdnxYnXf4JCP',
			42: '1WF80dpyCZJpwmp2bryuyxpBRSRLE7WHz',
			43: '1j7Ki9prL0iUoSQrxXVUftitt6d4rvZOj',
			44: '1_gdR4AI_Wevlemk8ZvXkdMw16IA00VAc',
			45: '1MPFWlTDWCW43MZcKOwPNBlMrmZgj_btU',
			46: '1Yal2cUtOiaUfkCiY_tCeAwCYTY_svOQ-',
			47: '1IfzEyxiQvVrGtcRzjQd8PPr-kBL3zX2O',
			48: '1VeQHpVeEbyiOlj7gPW3AQDn5g0CNB59B',
			49: '1Zz-uXoPobah1YBUpm2Tw-sfiTRC3UzKW',
			50: '1K6-Qq04uMdBihdhlgr2TwxFE689wKxqk',
			51: '1a3TKvAN2a-pYPqFAz2P5sv0Yql622_Jm',
			52: '1lsWgKAb21jntMeEmtrhA8IICi11R5brG',
			53: '1W9hN_5J9QXYWIpzKpXsFgdfZI52EPyTc',
			54: '1xwLvqT1J7ZAHxLPlckhp7CcZ2LhEKTH1',
			55: '1PYpazKK4-IJfVi29PJTj1pM6FmkVgsgH',
			56: '1HuVXzWLBqoPBuAn9XDub29ipRAVV-Q25',
			57: '1ybvQMpHy2bJfDFa6HNXl_hMCgY-654p_',
			58: '1Mp_QaNduOanqonI4iJKbMNJHUe7BrI5n',
			59: '1COuH6f34-4B86FLYrS7RbPU7Kg8FodP1',
			60: '18akTk83f869sNITi7E1L_gYaex1PUR8k',
			61: '1dZmzCexGR7N2FWBmifmo-xNta7Ryzm2U',
			62: '11brVO5u3WEjUrhHnJFcK0YJju2XqJ4GP',
			63: '1Zf_TmMxe83nS7t9j3YUGOaLGVlgXer9V',
			64: '1f7Rvdv3ZOpGN_vnH7VCjE5Hw-aeTqGxD',
			65: '1KZZ8ekmw19_EKyYAeMoyvHTgi24TjWY0',
			66: '1Tpd9DGif0pO127Hh7VZyBz30xN0L9Fwe',
			67: '1aIv9Iwb_5YORE7mL3UHgV9ZB-Wlr1k0O',
			68: '17PpvjCoDrx7j3D_ISXygJHgOIA2Qugr6',
			69: '1XxWTvOT_MX-7HUTTzGlNktUkgIIFSF0o',
			70: '1LJQpsOIDMS_NXZVSyXyozZXzSRhhdVwo',
			71: '1H4frvVHTSfmzHPc1Up9GKEp4tIKKfkVH',
			72: '1I58mEQpQxqd8QGQp__1EqwgLcwT_MaVh',
			73: '1nRpiQMzX0o1erCCwxyov0_EzRo4HgHQY',
			74: '1pK3YUMf6Lotyx8ci9-dMIKEpyJujIaH0',
			75: '1WLvXDvSdI8iFytd1MFuXr6kfm4LQqD9v',
			76: '1cz7GcJSdM-ckmgfDFiZNJA23RkCAXatw',
			77: '1BqkyFMFktCIb4XgFSFxqkReXF8dONqnz',
			78: '1neDwrOUFs2QAs1I_cDPLJxnsIFf6_jPa',
			79: '1oHGx_mQHWX6BGK0g-VQmBe_if3rGzhbf',
			80: '1o8ZAAZ5OfS6GqoMlhtRgVyzkIhMhwjpg',
			81: '16oxNkJ5V8EGVFzQfvjNuInlFjzeBphEZ',
			82: '1AvO0Tdu5_tDh7Po9tJtFqo6_jdQ3PVEl',
			83: '1q1CRA8viQSrl0oWqGXMLz7XpEGR0OzaN',
			84: '1KxQtxRwlEwNt0IlgXAT6d-3dd7tKZU_L',
			85: '15FDSLRTuHrLFzEiDiUd_tupF1EnDudga',
			86: '1rJ_5FFtJO6d-YxEP1jDh8komlJmZ7S3H',
			87: '1Af5KtrS_RF2g1ST1iuW5F6vzVAodv1-5',
			88: '1eMSQmsZ67UKAP-fLJMBX3QypJlVYFkPV',
			89: '1IU92Hd6veCUV66bYjTlYYFxRIZrSTqdg',
			90: '1gNh_w0ARV-6afo3o7yKzaejjoobpmf-X',
			91: '1aG6nLk7xboDzk37w7hL5YOq3cvFG3iZ2',
			92: '1FUxwaXRwLxigXosVZqjwORAxRs8dFmLf',
			93: '1-KHLF2cm1V3hBCubx0IYLaPzoFPuPLK5',
			94: '1fBxpu6cSsLm6DCUIJNVVvOJZ9z-tIyqN',
			95: '1T2AJVTZAvVleUKHjXdoZ8QiAk2_IyLp3',
			96: '1otQQMyJAwPXGpoUDN_ThwgZtfXdXYOKO',
			97: '1ngL93JjBcKfzC06aH921ikGhHcAaAu8D',
			98: '1ljoSWRb9LM6pXD2PWu1ty4jz8YREnp4B',
			99: '1xBcORWJJZZmkOiosOtF0ykBwTINBhj5P',
			100: '1Ids1m4pWpXBA_BpZ1yQ9pQHG1sRf7fdk',
			101: '1V9h03VNIavGTp4YmWUwzeWzxoygDfaTg',
			102: '1WYnDVqqCDqLJXnKmzb_qyAz2KHVgiQ1f',
			103: '1Q26vRMjKRWbJRMD6nDtclR3rtfZ4MofA',
			104: '11ZUmOChYoKLGW-qcxSEQgCN-9kQKSVl0',
			105: '1RmHtLY_Gfl753UfbRZ42dU61sORXY4X3',
			106: '1iPdcdMMCt1oJ_xi4D6PiWHzLgiAToBUm',
			107: '1OR8d4khW6vsPBYrgu4uZHl7NIBd61O4X',
			108: '1ML6C2CHDllcYFvF4OBhy5x8FbbZnvoVk',
			109: '1vNgN09vQSfj3UP5hZr-uPPKqzRRN1bxJ',
			110: '1knS45CVwEWDfwOtqp5QTgayMYo-iHK6C',
		},
		andreeaSilviu: {
			0: '1VEdFFpT8p6-CpCikAtkoAztOAzt7tJxn',
			1: '1IwqODeqpXWJSQV5GF_KCfWvgyV7PnLkO',
			2: '1rmnWygV-asqy-30YkKzQWskyYh_iOaZ7',
			3: '1XYNYQG7xphNzoSyd9C4-29lZ63IfVcF1',
			4: '1VeuS-PvdItm-s6xF8mCw6zgmnXSt_XNj',
			5: '1_kslETeLtZK-UiMYhBZwgX_aMEn2NNRs',
			6: '15ENckE6eRnmxdEntMYC1O9uf3nf8dNNx',
			7: '1vOSo2u5Jn7KghQE4Llxf9h5R9rbwLboJ',
			8: '1-o1EG3UhMERnaXINjeolDWrfRARcIKAo',
			9: '1KQ33muptYNJX37SalnRG0HAF6JcHc7Wl',
			10: '1Je2pNjf0eFORp6Tqaz2f0Zh8gXeIvycz',
			11: '1ExCgfX9URKjoZfPW7tp3aaOR3-HlUE9W',
			12: '1KeQU9VEU1NPibVFCLBagsZcJdh1FNXCA',
			13: '1dhLU-hfO2T1SHJBZhKaMJzWsaWtmCDiG',
			14: '16GKeoypeFHpeECdQzT6jnOvCChpRLoDX',
			15: '10UefScio7cMAg3B3-q3jiSAGPtnpmiON',
			16: '1Q1MWn6UEKxOfFzKrSa_VVkRGkpeWyjQS',
			17: '1V3-jRE_nw306NT5bJWqfC0mggOVENOYm',
			18: '1hGilxjv9EdXx-gtbvapgUIf_ZnR6sE8h',
			19: '166_o_e_BrZsraH4lOBo41SpVDLWSsL18',
			20: '1wHim4scvYzZgVadKGmbvg6GmL94bc0El',
			21: '1kZAa5TDYhEQAIhXK6qD0W3NnOVGTiH0j',
			22: '1-rAWFc1SwSK6JfvJSJjw37OKCyYipy-h',
			23: '1CNtngnu-nUFJLPbbIcxoaEdb7lNUbCEP',
			24: '1M9BtNgRpn-1ZUy2k5Acb7RdDFioKg3Lx',
			25: '11FOtT0ItkD-YV3LCIHWLtWu9DIPecQqH',
			26: '1x1ztzuLDL9mRvvGvy--UOVI5gavlnV-x',
			27: '1hSES18RViU0SI1ORARpdwMEtTjkidR6d',
			28: '1HPVEnzdRrncqpuMvbUtT6p1CKJBmXkrw',
			29: '1EHP_ymAjQEwk79Ah9xB76-O-07qEXk6i',
			30: '1HzCOpEXI8-1_u9SCyCZTaEu2V3e6H0y4',
			31: '1rTIOQKTyhqb01_EJtQjZd5C2MBzI5Hjy',
			32: '1whhj8AZzTzVQeUKlaz-yWhJeCPzfgiPS',
			33: '1-dshmY3xZ03ED--rG6dStolungkBy2Pc',
			34: '139gcq_bmjSe5yWBEF9X-dS7L2XDwxz4q',
			35: '18ioi3QwJKeF_TWFFGh6Jc3EVMvRI-Sk3',
			36: '1wDUTeKxIdIzKn5I5XY61vxb4QBcSCs5X',
			37: '1tXM7fpIK3-DU5stUaRXkEajvB3Iz6V8l',
			38: '1IQGPXLSPrmVeki1H5te_CXjFQcnXsrsq',
			39: '1Z5Z-asblFPCHSC1gE2U6uDDNovxiWKa_',
			40: '135eS_a_E5R5gtDuVnlRHUZK8x6wHlild',
			41: '1y9-xViSdPhNqUtzvAqUsT-qNbCDhmOvW',
			42: '1pGdCjRBlXi2EjuH71WezbPJwT21wnT2z',
			43: '1sMWrkZG4Sw-al9p8uLYQSKlNnmP15oQg',
			44: '1q3jO0LTe1AVvQdg6Atuq9GI5Fqb7OKPi',
			45: '1IWsBNLiNbvtrEh9UtWxRpzbriOLtUP6a',
			46: '1gAo1bQKoIjinjeg5Kx6rXjHJX6zItIty',
			47: '1cJZRkPslLd5kt9HNi6LsSU1V8i9yLTAr',
			48: '1DjgLxviLkEF7VJroRr2vbdN_slpugQZ-',
			49: '1jDwVEH5BgcSsjWhR-H-Yamck1zGiw99v',
			50: '1s_FcjfFhs_WMoTD7TfeW0BMRnY4O2SmB',
			51: '1gOuzbNoz_Qsn-QxXw61RZ5HULx2mURQI',
			52: '1zI7sIm6mwrUD1VpjnkfavMla0zAk-tyw',
			53: '17VrwJARsNRK-zfIiNyf4dPeZQVMuhnza',
			54: '1XlPHHirkeUGmVAxTiTAAloLHDwghgJm5',
			55: '1msqzpqhVnNwRxqgrK2Az3gY6JFOuvEqI',
			56: '1yJ5DyDC_cYPqL5A7r_2f4VM7cwuHz15J',
			57: '1W3tA-fe17Rxoe-7N8r_s2xkJADTUCGxg',
			58: '1iD7mZrTLLeblTE3F--WikfB5vLiKOi58',
			59: '1B4vGRggG3_xDgmqI8OqEwlNKAOTtzzQU',
			60: '1woKVp829344O37I6fYyftAjIwLDIRbGm',
			61: '1M8kPxpdkn8xzHYUw2b_jc9CDoOYw_45m',
			62: '1CZWbjDCBpMx9sWBKI19xiCQpIlp7bQEL',
			63: '1FrSIJ52AD7sArjS1ioU4tzFnANOh-7jf',
			64: '1CIuo51aPVT34vVZ9tzBprxiZHvMPTs8r',
			65: '1V3YarHfLwlCm-ZPJ8Bu8RxrQbvcBQsE5',
			66: '1kj0RpFqg4QXZe4atjFEdV2ehQQE0PJLR',
			67: '1k0wHNNza86GhLd6-M75PZamPhWdj0bbG',
			68: '1BWefP4hq0S4iUcY8TOFnjHJJubl1M_HS',
			69: '1Q44na5HCzAGJBVkxr-uFq2GcvKvM6rXi',
			70: '117xMB0hDFJRav4zov59pEp0Obn0MdVjQ',
			71: '19c3UJK-sL6My6bAycj5VbKg4r0wsRxQX',
			72: '1tzVpongmyDv4u8R189DY-kqos-yIIHku',
			73: '181fClImBRiBNF5WshIj30SdUCMVofLDT',
			74: '1MeTgtlC3bpIND7Jid_D0RaUG3JdLE8T5',
			75: '1rNETs6xr2yiHeMiQK4WCZQvqgCpcQGnp',
			76: '1MboUCH1XqcRe98Ylk6xirlvvSgj65gd9',
			77: '1-sX6sjT2i7KbnvyM-RyBAASw_ypqvwZG',
		},
		ioanaAugustin: {
			0: '1gHMUm8FtgRX3MwtzXHjixFceB-BCqFT3',
			1: '13VXHgjV7L7kIamuXc3pF0dJeG_q6Ccvf',
			2: '1N2brNAxCOA4N3-YszlmSLE0LfNfM3swv',
			3: '1cobLzGgUvkpbfsKroXeYTiBsdCjIG8KE',
			4: '1LAXvN8-i8cn8o2VnE8QmOFQcQqLcEoxz',
			5: '1UbRbuqCcryYQN3mGsyKuqwE9Df_X-XcS',
			6: '1HO9NkPMYCO69K_UiNtnqEOr4qOpkyWIE',
			7: '1AMnCGTeJcuTiW3EZU53co0zRd0J_1JIX',
			8: '1nYcP6PbceR-Aim7rLuA9ASeFWB5GkQRO',
			9: '1UnHzVFpnXvv4yVjFuo14WnztEE-0xR4D',
			10: '1NK21MAYqjJrFAreXNG2aaxFO3qr1YP1C',
			11: '1vb36sd6aicwOhPAhmYFyFiocIEIpV-kN',
			12: '1vylWoY4GfDW_7adp085kkjS7a509BgYP',
			13: '1YQ02U5y4Y6NPcvKeI8_d2XYfiAVkpord',
			14: '11xFg6CshD9YTl1DIiA_NB6iRLeQ-3oAi',
			15: '1-LWdYwJ2poU72EfAhvamAsNXKywX5J0G',
			16: '1HxBrS5KUiEtjUUjUL5f8LZMxbomsTs8k',
			17: '1mTsU9MhSc6dHjLCeVF0Rv312oM8ifJhJ',
			18: '1L41Bs2KeoP1-t2PTtmFA07Kf7CmjXd7v',
			19: '1gr935JWMZRIl4PQ2Ltz-HFZJD8dcqc1s',
			20: '1CTp0b1qQW9lLQn_dWJ27unRomJWvXwvm',
			21: '1nuMafZVm8FwDxGw11zYrKfw9t56eGVJF',
			22: '14_E71ELck3TrwqgVwKoIJZNv8V0edkGy',
			23: '1XEmfZdVB9J-T7QPZL1T9J_fZ-NJMaeXs',
			24: '1pWDxi7y_lD8tleUCiO1ChI5tPJh3knk-',
			25: '1dgoBOQwBk6qPtXOGwrJRxb2ljLwl47Wm',
			26: '1BrxHLJCT76WZpprEHWuz82kkZyCTabBL',
			27: '1SbBEI1_0EWqp_EcSumiNKy_f5xE_MNgO',
			28: '1UOHu4IkbqrX4if-f5axBOvTxsNvlU8aJ',
			29: '1VWZ1hG_xY7GVvhzDQP0e6xaj21HT7faJ',
			30: '1nwAEoyMHBGkSPYf59DGLLCDUvKrmuPPG',
			31: '15Js-aj9DtA2r6oaeSQ7HjR2AfA3KCZq5',
			32: '1WS-tbAqBpau3tIX4VPXyNbCvWwdTGuMK',
			33: '1pyBYhPeC4ssL4iMdzRxpdqe5czL1pau_',
			34: '1s_daNZlG8xLYn0kMXdjoRzfaZk0hrYW8',
			35: '1gl35fF8_Q3LlW7Vt-EFCiK6CHAoYBIfG',
			36: '1wpFMqV_hTAqzVFFF022oUUA9l64E4zG0',
			37: '16Yx6X8uFYu56o2kqUbhoUKkn3ChvoRKN',
			38: '1wTgQpb6EU1zpoerSflAGZU-b39iZq5R0',
			39: '1S9pGJNCCqt-zdaH5dvOAvUK6M6DXnZhJ',
			40: '1TrwC0zRZHmPT107UjZDF5_U1aJxVgv6s',
			41: '179CrD6MfXURxuLjrz2VqbjE6Ee5HI1rZ',
			42: '1XC2aXRqXQkOcHAtfaq6gfdp2KawRtqQy',
			43: '1u9hJ6HwMJRgXaNIg1uShSRJUZZsnCii0',
			44: '1EybyBBv-_7r_6H60CbRCgoiurO9mulWN',
			45: '1cLwSmsKLRSL0L--iOgP9WQ-w70AMToqW',
			46: '1cc9FdwlHnFB5LWEcau-gRBEfbxJII2JX',
			47: '1rsm6gjH1vT5SukgmAkZpbxouOZMWwidC',
			48: '1AfGDgHhMfoaVgih51ZE9NJDbcc2ae6zZ',
		},
		ramonaFabian: {
			0: '1Lb3L38eX3AYgfD0-nzkI73G0Uu6ewO4V',
			1: '1wNiXiIrH6Gc8pqEfH1QfGjnYkvfOiovb',
			2: '1o-xPdfB-SrJMtvjrii1SKAg2vBrsTWNO',
			3: '1DjO9gs9eQSskDaedBlnGZEmKshYp8YiL',
			4: '1f1y3DOaz8tMP4WNgnoDZ9DB5T5FGhVs5',
			5: '1IRUWvCVmorM8WJ54yJoWflx150cRAeuD',
			6: '1qKSEGNL2JS7J4xMnuPfzIqygY8ldKums',
			7: '1uYD-KwXpLwsL8ctY2_iWis1AKhW8dL6-',
			8: '1FnVCiihXXAgfzSrSoSBtnWDoFkejkZa9',
			9: '13pNvs1HZ0l6EicvyWIwsc0r5otCOvDzC',
			10: '15feCywxMufOh4pE9ByCSYKdiiaiQDELW',
			11: '1nQZalcaRipEEmymBVmA3i73MXc3QkAm6',
			12: '19zKsgsSK-x06CznZKe-X1hm0tFFKARdO',
			13: '1Bt5VIxGDVyfjgk0fV29BJpWuYQi4kCQJ',
			14: '1KCnSDpVNcNl-kTxYB6HE6VD7UrWZl4B9',
			15: '1vBHwTwPrnGmj1OwCDyIbI4MEAtnSm5nH',
			16: '1C2swOGJJdXeA9i_xEGEvO30tYAfb16_u',
			17: '1WPDF3f11uWrs8i0IFZsZGA724a0U8dTn',
			18: '1wr1oP_4gzL2PYJXSN-Ej-UbBltNg3Vex',
			19: '1GLlHp-6kEXMEA29cb2hy-uJX0cSOOtlj',
			20: '1TYW8LHyqznFgoiO3ry0oAgesyMf-qREk',
			21: '1nX-ONBNNI4gwilHcaKSFmYJ9o1xezXD-',
			22: '18vHTlgRMXK5M8NdRIkh031MLW6IvOM3c',
			23: '1Qjb020PKZKEZqR6I1X9tbYFEtBQk3n9X',
			24: '1wGvolxNg4sVXZmGtNNoshQ9Hx3CVTRrc',
			25: '1u9ziGSyNGS7gNnolh9mW9L5jk8KtYYRO',
			26: '1cbmaqZy-0HTD3xEuEHDudefnmGqGzOEZ',
			27: '1uWU4IEs_qYV34EidFMTcvEefKnkMWhjD',
			28: '1UbCnda_mnQWxdXhBh8eOQsvgUqpXQrCA',
			29: '1vvBQ_BzfZ4780_XTQKleLbC8XPn95U8-',
			30: '1Ah-Ti3rlADGBSm7qzzTlaVQXU-c1FKD5',
			31: '1qq4T-0ffNt_sALldJ2jog_4UqHzehtvp',
			32: '1N94UHpl9a7vdVVL5eH-4ZJoZzZbFqgQS',
			33: '1GXDMrs4qYSmrRSCC9V7R8kvyRJIkyS7D',
			34: '10WxMiSifjTXmnQPZILXydPkW2E2ohv1X',
			35: '1xhE-rCJ0N8rvFBwa2VG2Rl6efnjA_IsT',
			36: '1golaFyZy1-b2nqU38u51yGbWKvxbd6J9',
			37: '1s2rcNdx2ijaD5zAsj3pBIRamU8qiG_j0',
			38: '1kBo2-YdpT05ZQI2jQHsaKSDsf3m-Koih',
			39: '1DTLRtmV0qSZ3uFn9rKU74MYqCDBudQND',
			40: '1omCCE5Peb17dU4WNGRl_ErmUKsW8MCFo',
			41: '1o6hebsREZwJGg2-7YVptvsHxOBuNAf6R',
			42: '1nOnvJnIrF5OYInTFACjmtnvssnsOYas_',
			43: '1lFGoiKaqbW33byRCiIbNDxn4LScVT_nc',
			44: '1_Syp28CG6453bv6Gi-F0DKxbujwcwu3b',
			45: '1hu2MHqZ20-8hk0HBmxAPVSjQL89M2GD-',
			46: '1AL9TX32BPDBdcQOAmC-rinJud-g2EKpu',
			47: '1cdBr6GAYxaFPWdOiUZbK2YelvCY6GcBZ',
			48: '1FmdyNYplRLsx_jMd51ZMeuBqwnLjDeCW',
			49: '1YvIwhaw5LSRL8WH539KT9AOnekT78PDM',
			50: '1QI8DwZNlfoREt5KUKAVA_CMQo1AXEl0J',
			51: '1hZo12HI-8lzhjs3orR1S9qg0lvwXq14W',
			52: '1DrWoWVS5mwJTOMT4Pa5xv_UIAnyur1LS',
			53: '1RRTUKBNieV_gwcI6N_6-np3bMfExTLQI',
			54: '1vhMpqh9HNcOnMHS_WrX04WYiYpOpc8rZ',
			55: '1voXCHAF9bLdcJwg7kFe5KALzr4K5mT26',
			56: '1bAoeD8MABeiTfjWziA9BmnRyKrSX4Sre',
			57: '1_buKwf6OIxQFvxhCDLrqS9XwA9BeKuKu',
			58: '1PYn4s-SxkjZhfLruHf0wOWcNeCYUlHs9',
			59: '1Wfib72eztNM70hA39ZEJwoePQHWxKwji',
			60: '1oN-V59rTsI1EAHsE8C9vObmS2w1PUKd2',
			61: '15x1OhrDYhpfTtAYr4Ba-a8mdzfm--30I',
			62: '1VqUk3EMc0IShCB6K92BtgvsqKya0VR4l',
			63: '18oWquo1y_tf3Q1nL6RqBl-c-3lN9WUiQ',
			64: '1BNm6bxcCbrzJKCBBM81kUOOuU-YWbePy',
			65: '1HRubgh75HxKN2eTYOPSZDTLMQbbRnlYZ',
			66: '1lsYzpz86aKtyv8TFTrBey1t7coKVCBkO',
			67: '1iEGy6cQsBbrcolCPiSR2QvKbYvaOI1Wr',
			68: '1hvIaeuiV16AAxUz8FiZwbOYOaUA54s7y',
			69: '1lQE3fi59dRj6WWFDMhHtYi4kyxiNHEG0',
			70: '1iySp0MS8nqNYdDl2m4PfPxiHvp440vjB',
			71: '1VWd1rrJoVa_9dWDswrYI3GL6Gbsxkz7L',
			72: '1XqvJYRzmxKutijVS5IYF1zscLWiRg1fA',
			73: '11oJFVQpvI3U6sCxwE-oKaQiXnvPSXvEy',
			74: '1_gI5fpealvhk7LveEzO3V5KAShfIYLNB',
			75: '1M5MOad-slUcwcMw1k78nWwYUioUn24b6',
			76: '1enEn4LJa6L9wsuIgCY-WHhVj9vMU7jus',
			77: '1TpXI3Y9oguDDiF0VN3xfblAWIIpiG1cP',
			78: '1JgupDD9HMyxCbxjJVwX3oob_lVszT92j',
			79: '1TKus6eNsUr8S2dGN7taAVie5pMEBrxtH',
			80: '1CVAZqPlBwh-30cdEnipGq8zszmdVtAZe',
			81: '1zmsjEsjgRgFNp6dTK7N-ys7EaO6UXWjG',
			82: '1rOjsKmPwuiME2eNp3n9KdLu0s9fTm1W_',
			83: '1hw0aOvmsvYsPordA-twOYd4XCHpeAmYJ',
			84: '1TNaGfFEJZzDG0iJozLzfpvYITRaLrF66',
			85: '1DC485p-xrmbbkpubjuZEfe2hjCiTMytE',
			86: '13lkBZu2r1N98Le5HPWxsibCBWHLRUzgJ',
			87: '183U3sNY0X11Vl4kTxGhW9FeS1MIPezZ0',
			88: '1nHBzCdUtcBi8VNlsS9NdmlxyfA9XDRIw',
			89: '1T88SBU-pcLR11eEdafPDC2vP3nkhooYr',
			90: '1izS8YouImxvfJAQRZicko51MOoPVv3iO',
			91: '1x5YJn3VvaNHaSnuzDToQzlH8HZkVbM1b',
			92: '1YfIkzgz63GhODrZ4ioRqft4e2s1DlwkW',
			93: '14-3JWeCuHtZg5zhxfq3UbSGJ-rU5VMTS',
			94: '1swQJrP70JVolil6GYCM5CW-VhIpKQ2Jx',
			95: '1AelyW21-0UmP8SbGLEjU_5OcI2nCqhsU',
			96: '13Su2NwFuMykJasugXqO186fU_tdbnN78',
			97: '1Ug0YMZ-OLaKHCVEWwGtE85-7zW5swJgq',
			98: '1MxSnME-DlD176jK6bVwnQcZVW2JnA2e9',
			99: '1No6KRYfKit8adaegBXbRaRzLnMX_S_-D',
			100: '1s2g_JLT7zaJRKIxqT5v4zPTIKi6CKSF-',
			101: '1ftsTm48iWy7uD8Qm_7-cT41euh8S8E-V',
			102: '1c_mWH_cGdTS0v-SFe0GsqC9-Or-jQUWV',
			103: '1heCbGorlGsy7_RTa6suDrlMi-2_TVuAr',
			104: '1Cg4ygcDhW-peH6rTpKK16ed0QwEiZiWy',
			105: '1kTYAUv2xxqnkIm8pfYcJkGCmLKjpnIPo',
			106: '1tYKmBW791vkYZKWGIw1B6u2Tyjj7yuOk',
			107: '1D6ZP61OXQAGXtxx6INfAm2JjeGSuB8Bo',
			108: '1RejGDE01oSZ4SEXDIpPE4BR8LETUII2U',
			109: '1GUrh-x4Nn63KO3oEGJ70MsJ6LqkOTT2S',
			110: '1VEP47A8fa02G_GaZrJZWoR8xZHkENeie',
			111: '1_1pqKvCmMsE_bujS35Vtzvb3qybp4_49',
			112: '1CEW8hmhqnoYJYY5ODozUQEe7435izklZ',
			113: '1Ram7Rf2IsgDtUWc1KNcixDerTkuyDGla',
			114: '1iQeBIrLXtd-0K4t403hFSHgmEsVtxRZQ',
			115: '1uNtvUrTNvKk8U1UayTYB2tuZS2EF6A5X',
			116: '1U5pytfq14m8IaYxbJE8qkN3xkllLb9w9',
			117: '1K_sTRDOrJQedWLaOT_WordvY3uLbMdJG',
			118: '11DEspdaob0ISLwcp_IJQhn0PMwtYXMWF',
			119: '13jQZ-cr_1XD9LV72S16f4Y9KIgStOTfE',
			120: '1qWOtoO0G5uj5udnagoGhSvHYCzhL1CaX',
			121: '1vvdRET2SYnkS9qUvLhZ-fpR6gcMrcYTk',
			122: '19uXroFXc69FavARECkUskYtCWODBYHJm',
			123: '1-QnliW8UF0_hkOYlBXSK3SYOpFxm1GKo',
			124: '1Z0VZ0eit6NQxoVJdbIdg3zndIqqaZTjj',
			125: '1QUhX0g9m1yUiPos2yi5fOm9qvsHJpTRP',
			126: '1PMz_E7y_htINCOGxWRIQPPgQl4hEM12R',
			127: '1GsYRl0tbaJAcJ8cJek1CiAvhoL-y0Xfs',
			128: '1IpeHhKs1FMZUCKua5ZcRCqsQFHFl-tP-',
			129: '1pg6nALrXQm1NfunjAfifob_9pDrbq8qI',
			130: '11K5MWFVKJuFC2BVIATV_JzXveqKpAwbh',
			131: '1MiEHX8Yq-MbqIPidqFdX-FXXDnjTtyLW',
			132: '16ghwxwU4gycJ2iw3y0fdWfB-frJzMfJ9',
			133: '1JuFG2lnTqjHdn7rjTL8WaKrChIPTBHaE',
			134: '1HAJqSfiQJXcNoOsX6mPTTOvYyIGfk1Rr',
			135: '1AglI1sT2dCF84MwxPqu5sSZt7YvXXP2L',
			136: '1zBl-dr3thor45TXnXE55eEk3iZb4-K_S',
			137: '1DMTlU0Zp5Lf523jdMzvuzIfHiUdIiXfp',
			138: '1veCYOTrciT_39CLSxSyf55633MoOtIoQ',
			139: '1kx1FDAmPStjwubNFy3kNjwddOhCFJqMf',
		},
		toUseArray: {},
		photosToDisplay: 20,
		maxLength: 0,
		ticks: 0,
		displayGoTop: false,
	}
  },
  computed: {
		computeCoverImage() {
			return this.category.length && require(`../../assets/gallery/${this.category}/${this.folder}/${this.folder}-cover.jpg`);
		}
  },
  async mounted() {
	await this.scrollToTop()
	localStorage.setItem('route', '');
    window.dispatchEvent(new CustomEvent('route-localstorage-changed', {
      detail: {
        storage: localStorage.getItem('route')
      }
    }));
	let local = this.$route.params.title;
	switch (local) {
		case "bucharest-by-night":
			this.toUseArray = this.bucharest;
			this.maxLength = Object.keys(this.bucharest).length;
			this.folder = 'bucharest';
			this.category='lifestyle';
			this.title="Bucharest by Night";
			break;
		case "rome":
			this.toUseArray = this.rome;
			this.maxLength = Object.keys(this.rome).length;
			this.folder = 'rome';
			this.category='lifestyle';
			this.title = "Rome";
			break;
		case "cinque-terre":
			this.toUseArray = this.cinqueTerre;
			this.maxLength = Object.keys(this.cinqueTerre).length;
			this.folder = 'cinque-terre';
			this.category='lifestyle';
			this.title = "Cinque Terre";
			break;
		case "tedx-bucharest":
			this.toUseArray = this.tedxBucharest;
			this.maxLength = Object.keys(this.tedxBucharest).length;
			this.folder = 'tedx-bucharest';
			this.category='social';
			this.title = "TEDx Bucharest";
			break;
		case "tedx-universitatea-politehnica-bucharest":
			this.toUseArray = this.tedxPoli;
			this.maxLength = Object.keys(this.tedxPoli).length;
			this.folder = 'tedx-poli';
			this.category='social';
			this.title = "TEDx Universitatea Politehnica Bucharest";
			break;
		case "eliza":
			this.toUseArray = this.eliza;
			this.maxLength = Object.keys(this.eliza).length;
			this.folder = 'eliza';
			this.category='fashion';
			this.title = "Eliza";
			break;
		case "luiza-maria":
			this.toUseArray = this.luiza;
			this.maxLength = Object.keys(this.luiza).length;
			this.folder = 'luiza';
			this.category='fashion';
			this.title = "Luiza Maria";
			break;
		case "marinela":
			this.toUseArray = this.marinela;
			this.maxLength = Object.keys(this.marinela).length;
			this.folder = 'marinela';
			this.category='fashion';
			this.title = "Marinela";
			break;
		case "prod":
			this.toUseArray = this.prods;
			this.maxLength = Object.keys(this.prods).length;
			this.folder = 'prod';
			this.category='prod';
			this.title = "Products";
			break;
		case "adina-florin-tavi":
			this.toUseArray = this.adinaAndFlorin;
			this.maxLength = Object.keys(this.adinaAndFlorin).length;
			this.folder = 'adina-tavi';
			this.category='family';
			this.title = "Adina, Florin & Tavi";
			break;
		case "andreeas-pregnancy":
			this.toUseArray = this.andreaPregnancy;
			this.maxLength = Object.keys(this.andreaPregnancy).length;
			this.folder = 'andreaa-pregnancy';
			this.category='family';
			this.title = "Andreea's Pregnancy";
			break;
		case "olivia-marias-baptism":
			this.toUseArray = this.mariaBaptism;
			this.maxLength = Object.keys(this.mariaBaptism).length;
			this.folder = 'maria-baptism';
			this.category='family';
			this.title = "Olivia Maria's Baptism";
			break;
		case "ana-ciprian":
			this.toUseArray = this.anaCiprian;
			this.maxLength = Object.keys(this.anaCiprian).length;
			this.folder = 'ana-ciprian';
			this.category='wedding';
			this.title = "Ana & Ciprian";
			break;
		case "andreea-silviu":
			this.toUseArray = this.andreeaSilviu;
			this.maxLength = Object.keys(this.andreeaSilviu).length;
			this.folder = 'andreea-silviu';
			this.category='wedding';
			this.title = "Andreea & Silviu";
			break;
		case "ioana-augustin":
			this.toUseArray = this.ioanaAugustin;
			this.maxLength = Object.keys(this.ioanaAugustin).length;
			this.folder = 'ioana-augustin';
			this.category='wedding';
			this.title = "Ioana & Augustin";
			break;
		case "ramona-fabian":
			this.toUseArray = this.ramonaFabian;
			this.maxLength = Object.keys(this.ramonaFabian).length;
			this.folder = 'ramona-fabian';
			this.category='wedding';
			this.title = "Ramona & Fabian";
			break;
		default:
			this.toUseArray = {};
	}
    localStorage.setItem("route", "");
	const final = Object.keys(this.toUseArray).slice(this.ticks, this.photosToDisplay).reduce((result, key) => {
                    result[key] = this.toUseArray[key];

                    return result;
                }, {});
	this.toUseArray = final;
	this.infiniteScroll();
  },
  methods: {
		infiniteScroll() {
			let temp = {};

			switch (this.$route.params.title) {
				case "bucharest-by-night":
					temp = this.bucharest;
					break;
				case "rome":
					temp = this.rome;
					break;
				case "cinque-terre":
					temp = this.cinqueTerre;
					break;
				case "tedx-bucharest":
					temp = this.tedxBucharest;
					break;
				case "tedx-universitatea-politehnica-bucharest":
					temp = this.tedxPoli;
					break;
				case "eliza":
					temp = this.eliza;
					break;
				case "luiza-maria":
					temp = this.luiza;
					break;
				case "marinela":
					temp = this.marinela;
					break;
				case "prod":
					temp = this.prods;
					break;
				case "adina-florin-tavi":
					temp = this.adinaAndFlorin;
					break;
				case "andreeas-pregnancy":
					temp = this.andreaPregnancy;
					break;
				case "olivia-marias-baptism":
					temp = this.mariaBaptism;
					break;
				case "ana-ciprian":
					temp = this.anaCiprian;
					break;
				case "andreea-silviu":
					temp = this.andreeaSilviu;
					break;
				case "ioana-augustin":
					temp = this.ioanaAugustin;
					break;
				case "ramona-fabian":
					temp = this.ramonaFabian;
					break;
				default:
					temp = {};
			}
			window.addEventListener('scroll', () => {
				const scrollTop = Math.floor(document.documentElement.scrollTop + 250);
				const innerHeight = window.innerHeight;
				const offsetHeight = document.documentElement.offsetHeight;
				let bottomOfWindow = scrollTop + innerHeight > offsetHeight;
				if (bottomOfWindow) {
					this.ticks += 1;
					this.displayGoTop = true;
					const final = Object.keys(temp).slice(this.photosToDisplay * this.ticks, this.photosToDisplay * this.ticks + this.photosToDisplay).reduce((result, key) => {
											result[parseInt(key)] = temp[parseInt(key)];
											return result;
					}, {});
					const toRetrun = Object.assign(this.toUseArray, final);
					this.toUseArray = toRetrun;
					this.$forceUpdate();
				}
			})	
		},
		scrollToTop() {
			document.body.scrollTop = 0;
			document.documentElement.scrollTop = 0;
			this.displayGoTop = false;
		},
  },
};
</script>

<style scoped>
.background-color {
  background: #F0EFEB;
}
ul {
  display: flex;
  flex-wrap: wrap;
  margin: 2vmin;
}

li {
  list-style-type: none;
  height: 40vh;
  flex-grow: 1;
  margin: 2vmin;
}

li:last-child {
  flex-grow: 10;
}
img {
  max-height: 100%;
  min-width: 100%;
  object-fit: cover;
  vertical-align: bottom;
}
.img-cover {
	border-radius: 0;
}
.pointer {
	cursor: pointer;
	transition: all .4s;
	transform-origin: 50% 50%;
}
.pointer:hover {
	transform: scale(1.1);
}

@media (max-aspect-ratio: 1/1) {
  li {
    height: 30vh;
  }
}

@media (max-height: 480px) {
  li {
    height: 80vh;
  }
}

@media (max-aspect-ratio: 1/1) and (max-width: 480px) {
  ul {
    flex-direction: row;
  }

  li {
    height: auto;
    width: 100%;
  }
  img {
    width: 100%;
    max-height: 75vh;
    min-width: 0;
  }
}
.bottom-right-page {
	border-radius: 1vmin!important;
	position: fixed;
	bottom: 5%;
	right: 5%;
	background: linear-gradient(to right, #fff1e6, #eddcd2);
	transition: all 1s;
	transform-origin: 50% 50%;
}
.bottom-right-page:hover {
	transform: scale(1.1);
}
.custom-font {
  font-family: 'Cormorant Garamond', serif;
  color: rgb(0 0 0 / 55%);
}
</style>