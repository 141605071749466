<template>
<div>
  <h6 class="text-center custom-font my-4">
    Thoughts and ideas flow better when written down, here you can find some of mine!
  </h6>
  <div class="card bg-dark text-white">
    <img src="../assets/blog/coming-soon.jpg" class="card-img" height="700px" alt="coming-soon" style="object-fit:cover;">
    <div class="card-img-overlay d-flex justify-content-center">
      <p class="card-text my-auto font-billion">Coming Soon</p>
    </div>
  </div>
  <!-- <router-link to="#" class="text-decoration-none text-dark">
    <div class="card mt-4 shadow custom-look">
      <div class="row g-0">
        <div class="col-md-2 d-flex justify-content-center">
          <img src="../assets/test1.jpg" class="img-fluid rounded-start" alt="1">
        </div>
        <div class="col-md-10">
          <div class="card-body">
            <h4 class="card-title">Title</h4>
                <p class=" card-text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur faucibus vehicula urna ut auctor. 
                  Proin dapibus rhoncus fringilla. Etiam a vestibulum sapien, ac iaculis est. Vivamus tempor ante vehicula porttitor volutpat.
                  In hac habitasse platea dictumst. Donec convallis dignissim scelerisque....</p> 
            <p class="card-text"><small class="text-muted float-end"><hr/>Published on 12/Aug/2099</small></p>
          </div>
        </div>
      </div>
    </div>
  </router-link>
  <router-link to="#" class="text-decoration-none text-dark">
    <div class="card mt-4 shadow custom-look">
      <div class="row g-0">
        <div class="col-md-2 d-flex justify-content-center">
          <img src="../assets/test1.jpg" class="img-fluid rounded-start" alt="1">
        </div>
        <div class="col-md-10">
          <div class="card-body">
            <h4 class="card-title">Title</h4>
                <p class=" card-text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur faucibus vehicula urna ut auctor. 
                  Proin dapibus rhoncus fringilla. Etiam a vestibulum sapien, ac iaculis est. Vivamus tempor ante vehicula porttitor volutpat.
                  In hac habitasse platea dictumst. Donec convallis dignissim scelerisque....</p> 
            <p class="card-text"><small class="text-muted float-end"><hr/>Published on 12/Aug/2099</small></p>
          </div>
        </div>
      </div>
    </div>
  </router-link>
  <router-link to="#" class="text-decoration-none text-dark">
    <div class="card mt-4 shadow custom-look">
      <div class="row g-0">
        <div class="col-md-2 d-flex justify-content-center">
          <img src="../assets/test1.jpg" class="img-fluid rounded-start" alt="1">
        </div>
        <div class="col-md-10">
          <div class="card-body">
            <h4 class="card-title">Title</h4>
                <p class=" card-text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur faucibus vehicula urna ut auctor. 
                  Proin dapibus rhoncus fringilla. Etiam a vestibulum sapien, ac iaculis est. Vivamus tempor ante vehicula porttitor volutpat.
                  In hac habitasse platea dictumst. Donec convallis dignissim scelerisque....</p> 
            <p class="card-text"><small class="text-muted float-end"><hr/>Published on 12/Aug/2099</small></p>
          </div>
        </div>
      </div>
    </div>
  </router-link>
  <router-link to="#" class="text-decoration-none text-dark">
    <div class="card mt-4 shadow custom-look">
      <div class="row g-0">
        <div class="col-md-2 d-flex justify-content-center">
          <img src="../assets/test1.jpg" class="img-fluid rounded-start" alt="1">
        </div>
        <div class="col-md-10">
          <div class="card-body">
            <h4 class="card-title">Title</h4>
                <p class=" card-text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur faucibus vehicula urna ut auctor. 
                  Proin dapibus rhoncus fringilla. Etiam a vestibulum sapien, ac iaculis est. Vivamus tempor ante vehicula porttitor volutpat.
                  In hac habitasse platea dictumst. Donec convallis dignissim scelerisque....</p> 
            <p class="card-text"><small class="text-muted float-end"><hr/>Published on 12/Aug/2099</small></p>
          </div>
        </div>
      </div>
    </div>
  </router-link>
  <router-link to="#" class="text-decoration-none text-dark">
    <div class="card mt-4 shadow custom-look">
      <div class="row g-0">
        <div class="col-md-2 d-flex justify-content-center">
          <img src="../assets/test1.jpg" class="img-fluid rounded-start" alt="1">
        </div>
        <div class="col-md-10">
          <div class="card-body">
            <h4 class="card-title">Title</h4>
                <p class=" card-text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur faucibus vehicula urna ut auctor. 
                  Proin dapibus rhoncus fringilla. Etiam a vestibulum sapien, ac iaculis est. Vivamus tempor ante vehicula porttitor volutpat.
                  In hac habitasse platea dictumst. Donec convallis dignissim scelerisque....</p> 
            <p class="card-text"><small class="text-muted float-end"><hr/>Published on 12/Aug/2099</small></p>
          </div>
        </div>
      </div>
    </div>
  </router-link> -->
</div>
</template>

<script>
export default {
  mounted() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    localStorage.setItem('route', 'blog');
    window.dispatchEvent(new CustomEvent('route-localstorage-changed', {
      detail: {
        storage: localStorage.getItem('route')
      }
    }));
  }
}
</script>

<style scoped>
@media (min-width: 992px) {
  .rounded-start {
    border-top-left-radius: 1.25rem!important;
    border-bottom-left-radius: 1.25rem!important;
  }
}
@media (max-width: 992px) {
  .rounded-start {
    margin-top: 10px;
  }
}
.custom-look {
  border-radius: 1.25rem;
  transition: all 1s;
  transform-origin: 50% 50%;
}
.custom-look:hover {
  transform: scale(1.1);
}
.font-billion {
  font-family: Billion;
  font-size: 12vmin;
}
.custom-font {
  font-family: 'Cormorant Garamond', serif;
  letter-spacing: 1px;
  color: rgb(0 0 0 / 55%);
}
</style>