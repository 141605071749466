<template>
<div class="container">
    <h3 class="kind-words-subtitle-font text-center mt-5" style="font-size:25px">
        Kind words
    </h3>
    <p class="kind-words-subtitle-font text-center">
        what did my clients say?
    </p>
    <div id="demo" class="carousel shadow-lg slide">
        <div class="carousel-inner">
            <div class="carousel-item active">
                <div class="carousel-caption">
                    <p class="custom-font">Te recomand cu tot sufletul. Inca de acum 3 ani cand am avut prima sedinta foto, am empatizat si 
                        am descoperit ca este foarte talentata si dedicata, insa de data aceasta  mi-ai intrecut asteptarile.
                        <br><br>
                        Ai reusit sa surprinzi  toata fericirea noastra,si ne-ai creeat niste amintiri superbe!
                        Iti multumim mult 🤗😘🤗 <br><br> See you la sedinta baby newborn
                        </p>
                        <img @click="goToPhotos('family', 'andreeas-pregnancy')" src="../assets/testimonials/andreea-testimonial.jpg"
                         alt="andreea-testimonial"
                         title="Click to see the album"
                         class="img-fluid mt-2">
                    <div id="image-caption" title="Click to see the album" class="scale"><span @click="goToPhotos('family', 'andreeas-pregnancy')" class="kind-words-font scale">Andreea</span></div>
                </div>
            </div>
            <div class="carousel-item">
                <div class="carousel-caption">
                    <p class="custom-font">
                        Au fost superbe pozele. Ne-au plăcut super mult  🤗
                        <br>
                        <br>
                        Am apreciat super mult implicarea ta ,adică gesturile mici când strigai si încercai
                        sa destinzi atmosfera tensionata, când le spuneai sa râdă si cum sa stea la poze.
                        <br>
                        <br>
                        Am fost super incantata si de cum au ieșit pozele dar cel mai mult mi-a plăcut ca ai fost super implicată 🤗
	
                    </p>
                    <img @click="goToPhotos('family', 'andreea-silviu')" src="../assets/testimonials/andreea-silviu-testimonial.jpg"
                        alt="andreea-silviu-testimonial"
                        title="Click to see the album"
                        class="img-fluid mt-2">
                    <div id="image-caption" title="Click to see the album" class="scale"> <span @click="goToPhotos('family', 'andreea-silviu')" class="kind-words-font">Andreea &amp; Silviu</span></div>
                </div>
            </div>
        </div>
				<i>
				<button class="carousel-control-prev" type="button" data-bs-target="#demo" data-bs-slide="prev">
					<span class="arrows-background" aria-hidden="true">
						<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-left" viewBox="0 0 16 16">
							<path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
						</svg>
					</span>
					<span class="visually-hidden">Previous</span>
				</button>
				</i>
				<button class="carousel-control-next" type="button" data-bs-target="#demo" data-bs-slide="next">
					<span class="arrows-background" aria-hidden="true">
						<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
            </svg>
					</span>
					<span class="visually-hidden">Next</span>
				</button>
    </div>
</div>
</template>

<script>
export default {
    methods: {
        goToPhotos(linkPath, titleParsed) {
            this.$router.push({ path: `/${linkPath}/${titleParsed}` });
        }
    }
};
</script>

<style scoped>
#demo {
    border: 3px #F0EFEB solid;
    background: linear-gradient(120deg, #fff 50%, #F0EFEB 50%);
    max-width: 900px;
    margin: auto
}

.carousel-caption {
    position: initial;
    z-index: 10;
    padding: 5rem 8rem;
    color: rgb(0 0 0 / 55%);
    text-align: center;
    font-size: 1.1rem;
    font-weight: bold;
    font-family: 'Cormorant Garamond', serif;
    letter-spacing: 1px;
}

@media(max-width:767px) {
    .carousel-caption {
        position: initial;
        z-index: 10;
        padding: 3rem 2rem;
        color: rgb(0 0 0 / 55%);
        text-align: center;
        font-size: 0.7rem;
        font-family: 'Cormorant Garamond', serif;
        letter-spacing: 1px;
        font-weight: bold;
    }
}

.carousel-caption img {
    width: 6rem;
    border-radius: 5rem;
    margin-top: 2rem
}

@media(max-width:767px) {
    .carousel-caption img {
        width: 4rem;
        border-radius: 4rem;
        margin-top: 1rem
    }
}

#image-caption {
    font-style: normal;
    font-size: 1rem;
    margin-top: 0.5rem
}
.scale, img {
  transition: all 1s;
  cursor: pointer;
  transform-origin: 50% 50%;
}
.scale:hover, img:hover {
  transform: scale(1.1);
}
@media(max-width:767px) {
    #image-caption {
        font-style: normal;
        font-size: 0.6rem;
        margin-top: 0.5rem
    }
}

.arrows-background {
    background-color: rgb(212, 175, 55, 0.6);
    padding: 1.1rem
}

@media(max-width:767px) {
    .arrows-background {
        padding: 0.6rem
    }
}

.carousel-control-prev {
    justify-content: flex-start
}

.carousel-control-next {
    justify-content: flex-end
}

.carousel-control-prev,
.carousel-control-next {
    transition: none;
    opacity: unset
}
.kind-words-font {
  font-family: 'Prata', serif;
  color: #D4AF37;
}
.kind-words-subtitle-font {
  font-size: 17px;
  font-family: 'Prata', serif;
  color: rgb(0 0 0 / 55%);
}
</style>