<template>
  <div>
    <div id="carouselExampleFade" class="carousel slide carousel-fade" data-bs-ride="carousel">
      <div class="carousel-inner">
         <div class="carousel-item active" data-bs-interval="4000">
           <img src="../assets/landingPageImages/cover.jpg" class="d-block img-fluid adapt-screens" alt="cover-1" style="object-fit:cover;">
            <div class="carousel-caption">
              <h1 class="inCarouselStyle">Oana Subea</h1>
              <div class="d-none d-md-block">
                  <p>
                    <a @click="goToGallery('wedding')" class="custom-text">WEDDING</a>
                    /
                    <a @click="goToGallery('lifestyle')" class="custom-text">LIFESTYLE</a>
                    /
                    <a @click="goToGallery('family')" class="custom-text">FAMILY</a>
                    /
                    <a @click="goToGallery('fashion')" class="custom-text">FASHION</a>
                    /
                    <a @click="goToGallery('social')" class="custom-text">SOCIAL EVENT</a>
                    /
                    <a @click="goToImages('product-photo', 'prod')" class="custom-text">PRODUCT PHOTOGRAPHY</a>
                  </p>
                </div>
            </div>
         </div>
         <div v-for="index in 8" :key="index" class="carousel-item" data-bs-interval="4000">
            <img :src="require(`@/assets/landingPageImages/cover${index}.jpg`)" class="d-block img-fluid adapt-screens" :alt="`cover-${index}`">
            <div class="carousel-caption">
              <h1 class="inCarouselStyle">Oana Subea</h1>
              <div class="d-none d-md-block">
                <p>
                  <a @click="goToGallery('wedding')" class="custom-text">WEDDING</a>
                  /
                  <a @click="goToGallery('lifestyle')" class="custom-text">LIFESTYLE</a>
                  /
                  <a @click="goToGallery('family')" class="custom-text">FAMILY</a>
                  /
                  <a @click="goToGallery('fashion')" class="custom-text">FASHION</a>
                  /
                  <a @click="goToGallery('social')" class="custom-text">SOCIAL EVENT</a>
                  /
                  <a @click="goToImages('product-photo', 'prod')" class="custom-text">PRODUCT PHOTOGRAPHY</a>
                </p>
              </div>
            </div>
         </div>
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
    <div class="p-4">
      <h2 class="text-center font-prata">FINE ART PHOTOGRAPHY</h2>
    </div>
    <div class="container mt-2">
      <p class="text-center color-gold"><i>Welcome to my website, feel free to look at some of my works and
        <router-link to="/contacts" class="color-gold">contact me</router-link>
        for future events :)  </i></p>
      <div class="row py-5">
        <div class="col-lg-5 col-md-12 col-sm-12 d-flex justify-content-center my-auto">
          <div id="square">
            <p class="custom-font justifyText">
              Let’s make art together!
              <br>
              <br>
              Hi there! I’m so happy that you are here!<br>
              My name is Oana. I've been passionate about photography since I was little (like 14th years old).
              The words that describe me the best are: SOUL, PASSION and VISION.
              <br>
              <br>
              I put my soul in every project I make, every shooting and every event, and soul is what I want
              to capture with my photos. So don’t expect me to put you in the right position to get the best shots.
              I don’t believe in that. Rather, wait for me to guide you, to make you laugh and feel comfortable.
              This is how your soul comes out to light and I can capture it.
              <br>
              <br>
              I love to play with natural light and its shadows. My style is bright, romantic and sometimes colorful and cinematic
              <br>
              <br>
              So… if you are looking for someone to capture your most important moments in this style, YOU ARE IN THE RIGHT PLACE!
            </p>
            <hr style="height:2px">
            <p class="custom-font justifyText">
              Te invit sa creăm artă împreună!
              <br>
              <br>
              Bună mă bucur foarte mult că te afli aici!<br>
              Eu sunt Oana. Iubesc fotografia și arta în general încă de când eram mică (din jurul vârstei de 14 ani).
              Cuvintele care mă descriu cel mai bine sunt: SUFLET, PASIUNE și VIZIUNE.
              <br>
              <br>
              Pun cu adevărat mult suflet în fiecare proiect, fiecare ședință foto și fiecare eveniment pe care îl fotografiez,
              de aceea îmi doresc sa imortalizez la rândul meu suflete. Nu sunt genul de fotograf care să te pună într-o anumită 
              poziție pentru a obține cea mai buna fotografie. Nu cred în asta. Așteaptă-te mai degrabă să te ghidez, să te fac să
              râzi și să te fac sa te simți confortabil. În acest fel sufletul tău va ieși la suprafață iar eu voi fi acolo să îl fotografiez.
              <br>
              <br>
              Îmi place foarte mult să lucrez cu lumina naturală și cu umbrele sale. Stilul meu este unul luminos,
              romantic iar uneori este colorat și cinematic.
              <br>
              <br>
              Așadar, dacă ești în căutarea unui fotograf care să imortalizeze cele mai importante evenimente din viața ta în
              acest stil, TE AFLI ÎN LOCUL POTRIVIT!
              <br>
              <br>
            </p>
          </div>
        </div>
        <div class="col-lg-7 d-flex justify-content-center my-auto">
            <img src="../assets/landingPageImages/landing-page-image.jpg" alt="landing-page" class="img-fluid"/>
        </div>
      </div>
      <Testimonials/>
    </div>
    <div class="position-relative px-2 background">
      <span class="position-absolute position-badge translate-middle badge rounded-pill bg-sneek">
        <h6 class="m-0 fs-5">Sneak peek!</h6>
      </span>
      <div class="row g-3">
        <div class="col-lg-2 col-md-4 col-sm-6 d-flex justify-content-center align-content-center">
            <img src="../assets/sneak-peek/sneak-peek-0.jpg" alt="test1" class="img-fluid"/>
        </div>
        <div class="col-lg-2 col-md-4 col-sm-6 d-flex justify-content-center align-content-center">
            <img src="../assets/sneak-peek/sneak-peek-1.jpg" alt="test2" class="img-fluid"/>
        </div>
        <div class="col-lg-2 col-md-4 col-sm-6 d-flex justify-content-center align-content-center">
            <img src="../assets/sneak-peek/sneak-peek-2.jpg" alt="test3" class="img-fluid"/>
        </div>
        <div class="col-lg-2 col-md-4 col-sm-6 d-flex justify-content-center align-content-center">
            <img src="../assets/sneak-peek/sneak-peek-3.jpg" alt="test4" class="img-fluid"/>
        </div>
        <div class="col-lg-2 col-md-4 col-sm-6 d-flex justify-content-center align-content-center">
            <img src="../assets/sneak-peek/sneak-peek-4.jpg" alt="test4" class="img-fluid"/>
        </div>
        <div class="col-lg-2 col-md-4 col-sm-6 d-flex justify-content-center align-content-center">
            <img src="../assets/sneak-peek/sneak-peek-5.jpg" alt="test4" class="img-fluid"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Testimonials from './Testimonials.vue';

export default {
  name: 'MainContainer',
  components: {
    Testimonials,
  },
  mounted() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    localStorage.setItem('route', 'homepage')
    window.dispatchEvent(new CustomEvent('route-localstorage-changed', {
      detail: {
        storage: localStorage.getItem('route')
      }
    }));
  },
  methods: {
    goToGallery(aModalToOpen) {
      const params = {
        aModalToOpen: aModalToOpen,
      }
      this.$router.push({
          name: 'gallery',
          params
          });
    },
    goToImages(linkPath, linkTitle) {
      let titleParsed = linkTitle.replaceAll('& ','').replaceAll('\'','').replaceAll(' ', '-').replaceAll(',', '').toLowerCase()
      this.$router.push({ path: `/${linkPath}/${titleParsed}` });
    }
  }
}
</script>

<style scoped>
.justifyText {
  text-align: center;
}
.carousel-caption {
  top: 30%;
  bottom: auto;
}
@media (min-width: 992px) {
  .inCarouselStyle {
    color: #D4AF37;
    font-family: 'Billion';
    font-size: 120px;
  }
}
@media (max-width: 992px) {
  .inCarouselStyle {
    color: #D4AF37;
    font-family: 'Billion';
    font-size: 60px;
  }
}
@media (max-width: 353px) {
  .carousel-caption {
    top: 0%;
    bottom: 0%;
  }
}
@media (max-width: 285px) {
  .carousel-caption {
    top: -20%;
    bottom: 0%;
  }
}
.custom-font {
  font-family: 'Cormorant Garamond', serif;
  letter-spacing: 1px;
  color: rgb(0 0 0 / 55%);
}
.custom-text {
  text-decoration: none;
  color: #fff;
  letter-spacing: 4px;
}
.custom-text:hover {
  color: #D4AF37;
  cursor: pointer;
}
.adapt-screens {
  height: 70vh;
  width: 100vw;
  object-fit: cover;
}
.card {
  border: none
}
.row {
  padding-bottom: 30px
}
.position-badge {
  top: 0;
  left: 20%;
}
.background {
  background: #F0EFEB;
  margin-top: 200px;
}
.background-sub-title {
  background: #F0EFEB;
}
.font-prata {
  font-family: 'Prata', serif;
  color: rgb(0 0 0 / 55%);
  letter-spacing: 3px;
}
.bg-sneek {
  background: #F0EFEB;
  color: #D4AF37;
}
.color-gold {
  color: #D4AF37;
}
#square {position:relative}
#square:before, #square:after, #square>:first-child:before, #square>:first-child:after {
    position:absolute; content:' ';
    width:80px; height: 80px;
    border-color:#D4AF37;
    border-style: solid; 
}
#square:before {
  top: -20px;
  left:0;
  border-width: 4px 0 0 4px;
}
#square:after {top:0;right:0;border-color:white;border-width: 1px 1px 0 0}
#square>:first-child:before {bottom:0;right:0;border-width: 0 4px 4px 0}
#square>:first-child:after {bottom:0;left:0;border-color:white;border-width: 0 0 1px 1px}
</style>
