<template>
  <div class="text-center text-lg-start bg-light text-muted mt-5">
    <section class="">
      <div class="container text-center text-md-start mt-5">
        <div class="row mt-3">
          <div class="col-md-3 col-lg-4 col-xl-3 mx-auto my-auto">
            <h5 class="my-4">
                <router-link @click.native="goToTop()" class="disableUnderline" to="/">Oana Subea</router-link>
            </h5>
          </div>

          <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4 mt-5">
            <h6 class="text-uppercase fw-bold mb-4">Quick links</h6>
            <p>
              <router-link @click.native="goToTop()" class="text-reset decorations" to="/about-me">About me</router-link>
            </p>
            <p>
              <router-link @click.native="goToTop()" class="text-reset decorations" to="/gallery">Gallery</router-link>
            </p>
            <p>
              <router-link @click.native="goToTop()" class="text-reset decorations" to="/contacts">Contacts</router-link>
            </p>
            <p>
              <router-link @click.native="goToTop()" class="text-reset decorations" to="/blog">Blog</router-link>
            </p>
          </div>

          <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4 mt-5">
            <h6 class="text-uppercase fw-bold mb-4">Contact</h6>
            <a href="https://www.instagram.com/oanasubea/" rel="noopener" class="text-reset decorations me-2" target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-instagram" viewBox="0 0 16 16">
                    <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"/>
                </svg>
            </a>
            <a href="https://www.pinterest.com/oanasubea/" rel="noopener" class="text-reset decorations me-2" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pinterest" viewBox="0 0 16 16">
              <path d="M8 0a8 8 0 0 0-2.915 15.452c-.07-.633-.134-1.606.027-2.297.146-.625.938-3.977.938-3.977s-.239-.479-.239-1.187c0-1.113.645-1.943 1.448-1.943.682 0 1.012.512 1.012 1.127 0 .686-.437 1.712-.663 2.663-.188.796.4 1.446 1.185 1.446 1.422 0 2.515-1.5 2.515-3.664 0-1.915-1.377-3.254-3.342-3.254-2.276 0-3.612 1.707-3.612 3.471 0 .688.265 1.425.595 1.826a.24.24 0 0 1 .056.23c-.061.252-.196.796-.222.907-.035.146-.116.177-.268.107-1-.465-1.624-1.926-1.624-3.1 0-2.523 1.834-4.84 5.286-4.84 2.775 0 4.932 1.977 4.932 4.62 0 2.757-1.739 4.976-4.151 4.976-.811 0-1.573-.421-1.834-.919l-.498 1.902c-.181.695-.669 1.566-.995 2.097A8 8 0 1 0 8 0z"/>
            </svg>
            </a>
            <a href="mailto:subeaoana@gmail.com" class="text-reset decorations me-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2zm13 2.383-4.758 2.855L15 11.114v-5.73zm-.034 6.878L9.271 8.82 8 9.583 6.728 8.82l-5.694 3.44A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.739zM1 11.114l4.758-2.876L1 5.383v5.73z"/>
                </svg>
            </a>
          </div>
        </div>
      </div>
    </section>

    <div class="text-center p-4 custom-font">
      © 2022 <a class="text-decoration-none custom-font" title="Send me an email" href="mailto:mazilu.eduard404@gmail.com">Eduard Mazilu</a>. All rights reserved.
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goToTop() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>

<style scoped>
.disableUnderline {
  font-family: 'Billion';
  font-size: 70px;
  text-decoration: none;
  color: #D4AF37;
}
.disableUnderline:hover {
  font-weight: 600;
}
.decorations {
  font-family: 'Cormorant Garamond', serif;
  letter-spacing: 1px;
  color: rgb(0 0 0 / 55%);
  text-decoration: none;
}
.decorations:hover {
  color: black!important;
  font-weight: 600;
}
svg {
  transition: all 1s;
  transform-origin: 50% 50%;
}
svg:hover {
  transform: scale(1.3);
}
.custom-font {
  font-family: 'Cormorant Garamond', serif;
  letter-spacing: 1px;
  color: rgb(0 0 0 / 55%);
}
.pointer {
  cursor: pointer;
}
</style>